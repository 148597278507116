import { combineReducers } from 'redux';
import searchSlice, { resetSearch } from './SearchSlicer'
import preloadedSlice, { resetPreload } from './PreloadedSlicer'
import listBuilderSlicer, { resetListBuilder } from './ListBuilderSlicer';
import authorisationSlice, { logout, logoutMessage } from './AuthorisationSlicer';
import riverlakeSlice  from './RiverlakeSlicer';
import userSlice, { reset } from './UserSlicer';
import { LOGOUT } from './Actions';
import { LogoutAPI } from '../API/APIHelper';


const appReducer  = combineReducers({
    search: searchSlice,
    preloadedData: preloadedSlice,
    listBuilder:listBuilderSlicer,
    authorisation:authorisationSlice,
    user:userSlice,
    riverlake:riverlakeSlice,
  });
  const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        console.log(action.hasNoTokens)
        if(action.hasNoTokens != true){
            try{
                LogoutAPI()
            }
            catch(e){
                console.log(e)
            }
        }
        var authState
        if (action.message==undefined){
            authState = authorisationSlice(state.authorisation, logout());
        }
        else{
            authState = authorisationSlice(state.authorisation, logoutMessage(action.message));

        }
        const listState = listBuilderSlicer(state.listBuilder, resetListBuilder());
        const preloadState = preloadedSlice(state.preloadedData, resetPreload());
        const searchState = searchSlice(state.search,resetSearch())
        const userState = userSlice(state.user,reset())
        
        return {
            ...state,
            search: searchState,
            preloadedData: preloadState,
            listBuilder: listState,
            authorisation:authState,
            user:userState
        };
    }
    return appReducer(state, action);
};

export default rootReducer;