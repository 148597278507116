import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { refreshTokens } from "../API/APIHelper";
import { setLoginCookies } from "../Services/CookieService";
import { useDispatch } from "react-redux";
const initialState = {
    authenticated:false,
    admin:false,
    hasRL:false,
    errorMessage:''
}
const authorisationSlice = createSlice({
    name: 'authorisation',
    initialState,
    reducers: {
        login(state,action){
            setLoginCookies(action.payload);
            return{
                ...state,
                authenticated:true,
                admin:action.payload.isadmin,
                hasRL:action.payload.isRL,
            }
        },
        logout(state){
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `tokenExpires=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            return {
                ...state,
                authenticated:false,
                admin:false,
                hasRL:false,
                errorMessage:''
            };
        },
        logoutMessage(state,action){
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `tokenExpires=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            return {
                ...state,
                authenticated:false,
                admin:false,
                hasRL:false,
                errorMessage:action.payload.message
            };
        },
        resetLogoutMessage(state){
            state.errorMessage=''
        }
    }
});
export const { login,logout,resetLogoutMessage,logoutMessage} = authorisationSlice.actions;
export default authorisationSlice.reducer;
export const authenticated = state=>state.authorisation.authenticated;
export const selectIsRiverlakeUser = state=>state.authorisation.hasRL;
export const selectIsAdminUser = state=>state.authorisation.admin;
export const selectLogoutMessage = state=>state.authorisation.errorMessage;