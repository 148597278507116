import { useEffect, useState } from "react"
import IntegratedSearch from "../../Models/SearchApiModels/IntegratedSearch";
import BasicSearch from "../../Models/BasicSearch";
import { formatDateToString, FormatNumberWithCommas, FormatSettlementCode, stringifyList } from "../../Services/FormatService";
import BasicSearchApiModel from "../../Models/SearchApiModels/BasicSearchApiModel";
import * as ApiHelper from "../../API/AnalyseAPIHelper";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import IntegratedListResults from "../../Models/IntegratedListResult";
import HomeIcon from '@mui/icons-material/Home';

export default function RLLinkMiniTable(props){
    const{uarns} = props
  const [resultsArray, setResultsArray] = useState<Array<IntegratedListResults>>([]);
    useEffect(()=>{
        console.log(uarns)
        if(uarns.length != 0){
            handleGetData();
        }
    },[uarns])

    const columns: GridColDef[] = [

        {
          field: 'firm_name', headerName: 'FIRM NAME', width: 78, minWidth: 78, headerAlign: 'left',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'address', headerName: 'ADDRESS', width: 458, headerAlign: 'left',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'postcode', headerName: 'POSTCODE', width: 80, headerAlign: 'left',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE', width: 104, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'assessmenT_REFERENCE_2023', headerName: 'ASSESSMENT REF (LIVE 2023)', width: 88, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined && Number(params.value) != 0 && params.value != '0' ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'description', headerName: 'DESCRIPTION', width: 175, headerAlign: 'left',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'psD_CODE', headerName: 'PSD', width: 34, minWidth: 34, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'scat', headerName: 'SCAT', width: 40, minWidth: 40, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div >
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'rateablE_VALUE_2023', headerName: 'RATEABLE VALUE 2023', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell left' : ''}>
              {params.value != null && FormatNumberWithCommas(params.value)}
              {params.value == null && params.row.flaG_2023 != null && <span>DELETED</span>}
              {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
            </div>
            
          ),
          valueFormatter:(value,row)=>{
            if(value != null){return FormatNumberWithCommas(value);}
            if(value == null && row.flaG_2023 != null)  {return 'DELETED';}
            return 'NOT';
          }
        },
        {
          field: 'effectivE_DATE_2023', headerName: 'EFFECTIVE DATE 2023', width: 100, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {formatDateToString(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row) => {
            if(row.rateablE_VALUE_2023 == null && row.flaG_2023 == null)  {return 'IN 2023';}
            return formatDateToString(value)
          },
        },
        {
          field: 'lisT_ALTERATION_DATE_2023', headerName: 'LIST ALTERATION DATE 2023', width: 100, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {params.value != null && formatDateToString(params.value)}
              {params.value == null && params.row.flaG_2023 != null && <span>COMPILED</span>}
              {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row) => {
            if(row.rateablE_VALUE_2023  == null && row.flaG_2023 == null)  {return 'LIST';}
            return formatDateToString(value)
          },
        },
        {
          field: 'compositE_2023', headerName: 'COMPOSITE 2023', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {params.value != 'C' && <span>&nbsp;</span>}
              {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
    
            </div>
          ),
        },
        {
          field: 'settlemenT_TYPE_2023', headerName: 'SETTLEMENT TYPE 2023', width: 150, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2023, params.value)}</span> : <span>&nbsp;</span>}
    
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatSettlementCode(row.lisT_YEAR,value)
          }
        },
        {
          field: 'compileD_RATEABLE_VALUE_2023', headerName: 'COMPILED RATEABLE VALUE 2023', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatNumberWithCommas(value)
          }
        },
        {
          field: 'lisT_PERCENTAGE_2023', headerName: 'LIST PERCENTAGE 2023', width: 60, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'percentagE_BETWEEN_2023_2017', headerName: 'PERCENTAGE BETWEEN 2023 AND 2017', width: 60, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'drafT_2023_RATEABLE_VALUE', headerName: 'DRAFT 2023 RATEABLE VALUE', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div className={params.row.flaG_2023 == null ? 'emptycell right' : 'bluecell'} >
              {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatNumberWithCommas(value)
          }
        },
        {
          field: 'rateablE_VALUE_2017', headerName: 'RATEABLE VALUE 2017', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell left' : ''}>
              {params.value != null && FormatNumberWithCommas(params.value)}
              {params.value == null && params.row.flaG_2017 != null && <span>DELETED</span>}
              {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            if(value != null){return FormatNumberWithCommas(value);}
            if(value == null && row.flaG_2017 != null)  {return 'DELETED';}
            return 'NOT';
          }
        },
        {
          field: 'effectivE_DATE_2017', headerName: 'EFFECTIVE DATE 2017', width: 100, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div className={params.row.flaG_2017 == null ? 'emptycell' : 'bluecell'} >
              {formatDateToString(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'IN 2017';}
            return formatDateToString(value)
          }
        },
        {
          field: 'lisT_ALTERATION_DATE_2017', headerName: 'LIST ALTERATION DATE 2017', width: 100, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {params.value != null && formatDateToString(params.value)}
              {params.value == null && params.row.flaG_2017 != null && <span>COMPILED</span>}
              {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'LIST';}
            return formatDateToString(value)
          }
        },
        {
          field: 'compositE_2017', headerName: 'COMPOSITE 2017', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {params.value != 'C' && <span>&nbsp;</span>}
              {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
            </div>
          )
        },
        {
          field: 'settlemenT_TYPE_2017', headerName: 'SETTLEMENT TYPE 2017', width: 150, headerAlign: 'left', align: 'center',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2017, params.value)}</span> : <span>&nbsp;</span>}
    
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatSettlementCode(2017, value)
          }
        },
        {
          field: 'compileD_RATEABLE_VALUE_2017', headerName: 'COMPILED RATEABLE VALUE 2017', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatNumberWithCommas(value)
          }
        },
        {
          field: 'lisT_PERCENTAGE_2017', headerName: 'LIST PERCENTAGE 2017', width: 60, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'percentagE_BETWEEN_2017_2010', headerName: 'PERCENTAGE BETWEEN 2017 AND 2010', width: 60, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
              {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
            </div>
          ),
        },
        {
          field: 'drafT_2017_RATEABLE_VALUE', headerName: 'DRAFT 2017 RATEABLE VALUE', width: 100, headerAlign: 'left', align: 'right',
          renderCell: (params: any) => (
            <div  className={params.row.flaG_2017 == null ? 'emptycell right' : 'bluecell'}>
              {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
            </div>
          ),
          valueFormatter:(value,row)=>{
            return FormatNumberWithCommas(value)
          }
        },
      ];
    const handleGetData=async()=>{
            try {
              var query = new IntegratedSearch();
              var basic = new BasicSearch()
              basic.uarn = uarns
              query.basic = new BasicSearchApiModel(basic);
              query.report = true
              var data = await ApiHelper.GetIntegratedList(query);
              data = data?data:[];
              setResultsArray(data);
            }
            catch (ex) {
              console.log(ex)
        
            }
    }
    const rows = resultsArray ? resultsArray : []; 
    return(
        <>
            {rows&&<DataGridPro
                  columns={columns}
                  rowHeight={22}
                  rows={rows}
                  columnHeaderHeight={22}
                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                  getRowId={(row: any) => row.keY_FIELD}
                  hideFooter
                  disableColumnMenu
                  disableColumnFilter
                />}
        </>
        )
    
}