import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import { selectIsRiverlakeUser } from '../../../Redux/AuthorisationSlicer';
const IntListOptions = {
  QuickPrintDAR: { id: 0, label: "Quick Print DAR", icon: "printicon" },
  ShowValuation: { id: 1, label: "Show Valuation", icon: "reporticon" },
  QuickPrintDARAndValuation: { id: 2, label: "Quick Print DAR and Valuation", icon: "printicon" },
};
const SumValOptions = {
  PrintQuickValuationReport: { id: 0, label: "Print Quick Valuation Report", icon: "printicon" },
  GetSchemeInfo: { id: 1, label: "Show All Valuations based upon this Scheme", icon: "showschemeicon" },
  Return: { id: 2, label: "Return To Search Results", icon: "returnicon" },
};
const RightClickOptions = {
  SearchGoogle: { id: 7, label: "Search in Google", icon: "googleicon" },
  SearchGoogleImages: { id: 9, label: "Search in Google Images", icon: "googleimageicon" },
  ShowMap: { id: 6, label: "Show in Google Maps", icon: "googlemapsicon" },
  AddToListBuilder: { id: 3, label: "Add to 'ListBuilder'", icon: "addlistbuildericon" },
  ExportToRiverlake:{id:8,label:'Export to Riverlake', icon: "riverlakeicon"},
  ShowEntryOnGOVUK: { id: 4, label: "Show Entry on GOV.UK", icon: "showgovicon" },
  ShowScheme: { id: 5, label: "Show Scheme", icon: "showschemeicon" },
};
const AuthSchOptions = {
  PrintQuickNoticeReport: { id: 0, label: "Print Quick Notice Report", icon: "printicon" },
  GetCaseInfo: { id: 1, label: "Show Assessments Related By Case", icon: "hammericon" },
  Return: { id: 2, label: "Return To Search Results", icon: "returnicon" },
};
const VTSOptions = {
  PrintQuickVTSPrint: { id: 0, label: "Print Quick VTS Print", icon: "printicon" },
};

const TableRightClickMenu = ({ contextMenuPosition, handleClose, handleMenuItemClick,table,subtable =false,archive = false  }) => {

  const isContextMenuOpen = contextMenuPosition.mouseY !== null && contextMenuPosition.mouseX !== null;

      const isRiverlakeUser = useSelector(selectIsRiverlakeUser)
  const [subMenuAnchor, setSubMenuAnchor] = useState(null);
  const handleMenuClose = () => {
    handleClose();
  };
  const handleSubMenuOpen = (event) => {
    setSubMenuAnchor(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchor(null);
  };
  const handleMenuClick = (item,param='') => {
    handleMenuItemClick(item,param);
    handleClose();
  };
  const handleBackgroundRightClick = (event)=>{
    handleClose();
  }
  return (<>
    <Menu
      open={isContextMenuOpen}
      onClose={handleMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        isContextMenuOpen
          ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
          : undefined
      }
      MenuListProps={{
        onClick: (event) => event.stopPropagation(), 
        onContextMenu: (event) => {
          event.preventDefault();
          handleMenuClose(); 
        },
      }}
    >
        {table == 0 &&Object.values(IntListOptions).map((element) => (                        
          <MenuItem sx={{p:'0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
            <span className={"rightclickicon "+element.icon}></span>
            <span className="rightclicktext">{element.label}</span>
          </MenuItem>                      
        ))}   
        {table == 1 &&Object.values(SumValOptions).map((element) => (                        
          (
            (subtable && element.id == 1) || 
            (!subtable && element.id == 2) ||
            (archive && element.id ==2)||
            (archive && element.id ==1)
          ) ? null : (
            <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
              <span className={"rightclickicon " + element.icon}></span>
              <span className="rightclicktext">{element.label}</span>
            </MenuItem>
          )                    
        ))}
      {table == 2 &&Object.values(AuthSchOptions).map((element) => (                        
          (
            (subtable && element.id == 1) || 
            (!subtable && element.id == 2)
          ) ? null : (
            <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
              <span className={"rightclickicon " + element.icon}></span>
              <span className="rightclicktext">{element.label}</span>
            </MenuItem>
          )                     
        ))}
        {table === 3 && Object.values(VTSOptions).map((element) => (
              <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
                <span className={"rightclickicon " + element.icon}></span>
                <span className="rightclicktext">{element.label}</span>
              </MenuItem>
        ))}
        {Object.values(RightClickOptions).map((element) => (    
        (
          (element.id == 6) 
        ) ? 
        <MenuItem sx={{p: '0px 4px 0px 4px'}} onMouseEnter={handleSubMenuOpen} onMouseLeave={handleSubMenuClose}key={element.id}>
        <span className={"rightclickicon " + element.icon}></span>
        <span className="rightclicktext">{element.label}</span>
        <span className="rightclickiconright"><ChevronRightIcon /></span>
        <Menu
          anchorEl={subMenuAnchor}
          open={Boolean(subMenuAnchor)}
          onClose={handleSubMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label,"address")} key={element.id+20}>Search by Address</MenuItem>
          <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label,"postcode")} key={element.id+30}>Search by Postcode</MenuItem>
          <MenuItem sx={{p: '0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label,"addressandpostcode")} key={element.id+40}>Search by Both</MenuItem>
        </Menu>
      </MenuItem>
        : (
          (isRiverlakeUser || element.id != 8)&&             
          <MenuItem sx={{p:'0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
            <span className={"rightclickicon "+element.icon}></span>
            <span className="rightclicktext">{element.label}</span>
          </MenuItem>  
        )             
        ))}
    </Menu>
    </>
  );
};

export default TableRightClickMenu;
