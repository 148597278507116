import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridColumnGroup, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../../Models/AuthorityScheduleResults';
import { cleanup } from '@testing-library/react';
import AuthorityScheduleAdv1 from '../../Models/AuthorityScheduleAdv1';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas, formatVTSStatus } from '../../Services/FormatService';
import FlagComponent from '../SubComponents/FlagComponent';
import AuthorityScheduleAdv2ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv2ApiModel';
import AuthorityScheduleAdv1ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel';
import VTS from '../../Models/SearchApiModels/VTSModel';
import HomeIcon from '@mui/icons-material/Home';
import { Height } from '@mui/icons-material';
import { setLoading } from '../../Redux/SearchSlicer';



export default function VTSTable(props) {
  const {uarn} = props
  
  const [vtsData, setVTSData] = useState<Array<VTS>>([]);
  const [selectedRow, setSelectedRow] = useState<VTS>();
  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [vtsData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN]);

  useEffect(() => {
    var row = vtsData.find(elem=>elem.key_field == rowSelectionModel[rowSelectionModel.length-1])

    if (row){
      setSelectedRow(row);

    }
  }, [rowSelectionModel])

  


  const handleRowClick = (e) => {
    const { row,id } = e;
    if (row.uarn !== selectedUARN) {
      //setSelectedRowID(id)
      store.dispatch(setSelectedUARN(row.uarn));

    }
  }
  
  async function fetchData() {
     
    store.dispatch(setLoading(true))
    setLocalLoading(true);
    
    try{
      var data;
        data = await ApiHelper.GetVTS(uarn);
        var dataarray = data ? data : [];
        data.forEach(element => 
          element.key_field = getRowId()
        );
        setVTSData(dataarray);

        var row = vtsData[0]

        if (row){
          setSelectedRow(dataarray[0]);
    
        }
       
        setLocalLoading(false);
    }
    catch(e){
      console.log(e)
    }
    finally{
    store.dispatch(setLoading(false))

    }
    
  }
 

  const handleRowOrderChange = (model) => {
    pageNo = 0
    fetchData();
    apiRef.current.scroll({ top: 0 });
  }


  const handleContextMenu = (event, row) => {

    event.preventDefault();

    if (event.shiftKey) {
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel,row.key_field]);
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.key_field == elem) > -1){
        }
        else{
          setRowSelectionModel([row.keY_FIELD]);
          if (row.uarn !== selectedUARN) {

            setSelectedUARN(row.uarn);
      
          }
        }
          
      }
    }

   
  };

  const onClipboardCopy = (data, event, { api }) => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    
    const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
    navigator.clipboard.writeText(columnsAsString + '\n' + data);
  }

  const columns: GridColDef[] = [
   
    { field: 'lisT_YEAR', headerName: 'LIST', width: 20, align: 'center' },
    { field: 'appeaL_STATUS', headerName: 'STATUS',  width: 110, align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatVTSStatus(params.value) || <span>&nbsp;</span>}
        </div>
      ),
     },
    
    
    { field: 'firM_NAME', headerName: 'FIRM NAME', width: 85 },
    { field: 'address', headerName: 'ADDRESS', width: 210 },
   
    { field: 'postcode', headerName: 'POSTCODE', width: 100 },
    { field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE NUMBER', width: 100},       
   
    { field: 'description', headerName: 'DESCRIPTION', width: 160 },

    { field: 'psD_CODE', headerName: 'PSD', width: 15 },
    { field: 'composite', headerName: 'COMPOSITE', width: 15,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} >
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value =='C' && <HomeIcon sx={{height:18,width:18}} color='secondary' />}
          
        </div>
      ),

     },
        
    { field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE',  width: 80, align:'right',
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
         {params.value != null && FormatNumberWithCommas(params.value)}       
          {params.value == null && <span>DELETED</span>}
        </div>
      ),
      },
    { field: 'effectivE_DATE', headerName: 'EFFECTIVE DATE', align: 'center', type: 'date', width: 110, valueFormatter: (params) => formatDateToString(params)  },
   
    { field: 'agenT_NAME', headerName: 'AGENT',  width: 110 },
    { field: 'agenT_POSTCODE', headerName: 'AGENT POSTCODE', width: 75 },

     { field: 'appeaL_NUMBER', headerName: 'APPEAL NUMBER', width: 75 },
    { field: 'suB_PROGRAMME_ID', headerName: 'SPROG ID', width: 90},      
    { field: 'starT_DATE', headerName: 'START DATE', align:'center', type: 'date', width: 110, valueFormatter: (params) => formatDateToString(params)  },
    { field: 'targeT_DATE', headerName: 'TARGET DATE', width: 75, valueFormatter: (params) => formatDateToString(params) },

    { field: 'tribunaL_DATE', headerName: 'TRIBUNAL DATE',  type: 'date', width: 110, valueFormatter: (params) => formatDateToString(params)  },
    { field: 'tribunaL_VENUE', headerName: 'TRIBUNAL VENUE', width: 100 },
    { field: 'tribunaL_START_TIME', headerName: 'TRIBUNAL START TIME', width: 60},      
    { field: 'landS_TRIBUNAL_INDICATOR', headerName: 'LANDS TRIBUNAL',  width: 50 , headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
           {params.value == 'Y' ? <>YES</> : <span>&nbsp;</span>}
        </div>
      ),}
    
    
  ]

  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'ADDRESS/STATUS',
      children: [{ field: 'lisT_YEAR' }, { field: 'appeaL_STATUS' }, { field: 'firM_NAME' }, { field: 'address' },  { field: 'postcode' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'bA_REFERENCE_NUMBER' }, { field: 'description' }, { field: 'psD_CODE' } , { field: 'composite' },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    
   
    {
      groupId: 'INFORMATION SENT TO BILLING AUTHORITY',
      children: [{ field: 'rateablE_VALUE' }, { field: 'effectivE_DATE' }, { field: 'agenT_NAME' },  { field: 'agenT_POSTCODE' }, { field: 'appeaL_NUMBER' }, { field: 'suB_PROGRAMME_ID' },
         { field: 'starT_DATE' }, { field: 'targeT_DATE' },  { field: 'tribunaL_VENUE' },
         { field: 'Tribunal_Date' }, { field: 'tribunaL_START_TIME' },  { field: 'landS_TRIBUNAL_INDICATOR' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]
  const rows = vtsData || [];
  if (localLoading) {  
    return (
      <div className="loading">        
        <p>Loading...</p>
      </div>
    );
  }
 
  return (
     
    <div style={{ height: '100%', width: '100%', display: 'flex'}}>   
    <div className={selectedRow && selectedRow.decisioN_TEXT?'mainvtstable':'mainvtstable full'} style={{height:'100%'}}>
      {rows && rows.length > 0 ? (
       <DataGridPro rows={rows} columns={columns} rowHeight={22} columnHeaderHeight={22}
       
          sortingOrder={['asc', 'desc']}
          onRowClick={handleRowClick}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          hideFooter
          getRowId={(row: VTS) => row.key_field!}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          onClipboardCopy={onClipboardCopy}
          columnGroupingModel={columnGroupingModel}
          apiRef={apiRef}
          autosizeOptions={{
            includeOutliers: false,
            includeHeaders: false,
          }}
        />
      ): (<>
        {vtsData !=undefined && <p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
      </>)
      }

   </div>

   {rows && rows.length > 0 && selectedRow && selectedRow.decisioN_TEXT&&
      <div className='tribunaldecisiontab layoutcolumn' style={{ width: '15%',height:'calc(100% - 7px)'}}>
        <div className='header'>Decision Dated: {formatDateToString(selectedRow.decisioN_DATE)}</div>
        <div className='content'>{selectedRow.decisioN_TEXT}</div>
      </div>
    
    } 
   
    </div>
  );
}