import { useEffect, useState } from "react"
import * as ApiHelper from "../API/AnalyseAPIHelper";
import SendToRiverlake from "../Models/SendToRiverLake";
import { Button } from "@mui/material";
import ExportSession from "../Models/ExportSession";
import SendToRiverlakeDetails from "../Models/SendToRiverlakeDetails";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RLLinkMiniTable from "./TableComponents/RLLinkMiniTable";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import store from "../Redux/ConfigureStore";
import { setRiverlakeListFromSession } from "../Redux/RiverlakeSlicer";
import { setLoading } from "../Redux/SearchSlicer";

export default function RiverlakeSessionsComponent(props) {
    const [summarys,setSummarys]= useState<SendToRiverlake[]>([])
    const [summarysDetails,setSummarysDetails]= useState<SendToRiverlakeDetails[]>([])
    const [deleteID,setDeleteID]= useState<{id,index}>()
    const [editID,setEditID]=useState()
    useEffect(()=>{
        InitSummarys()
    },[])
    const InitSummarys = async()=>{
        store.dispatch(setLoading(true))
        var sumarray = await getSummarys()
        var displayarray:SendToRiverlakeDetails[] = []
        if(sumarray && sumarray.length>0){
            sumarray.forEach((elem,index)=>{
                displayarray.push(new SendToRiverlakeDetails());
                
            })
            setSummarysDetails(displayarray)
        }
        store.dispatch(setLoading(false))
    }
    const handleExport=async(rlSendToRiverlakeID,remoteClientID,exportID)=>{
         
        var response = await GetSingleSendToRiverlakeInternal(rlSendToRiverlakeID)
         
        if(response){
            await handleStartSession(exportID)
            await CreateItemToSend(rlSendToRiverlakeID)
            InitSummarys()
        }

    }
    const handleEditClick = (id,index)=>{
        const {listList,changesMade} = store.getState().riverlake

        if(listList && listList.length !=0 && changesMade){
            setEditID(id)
        }
        else{
            handleConfirmEdit(id)
        }
    }
    const handleSummaryClick=async(exportID,index)=>{
        var tempdetails = [...summarysDetails]
        if(summarysDetails[index].show){
            tempdetails[index].show= false
            setSummarysDetails(tempdetails)    

        }
        else{       
            tempdetails[index].show= true
            setSummarysDetails(tempdetails)  
        }
    }
    const handleConfirmEdit = (id)=>{
        var summary = summarys.find(elem=>id == elem.rlSendToRiverlakeID)
        console.log(summary)
        store.dispatch(setRiverlakeListFromSession(summary))
    }
    const handleDeleteClick =async(id,index)=>{
        setDeleteID({id,index})
    }
    const handleDelete = async()=>{
        const {id,index} = deleteID!
        await ApiHelper.DeleteSendToRiverlake(id)
        var newdets = [...summarysDetails]
        newdets.splice(index,1)
        await getSummarys()
        setSummarysDetails(newdets)
        setDeleteID(undefined)
    }
    const getSummarys=async()=>{
        try{
            var response = await ApiHelper.GetSendToRiverlake()
            if(response){
                setSummarys(response)
                return response;
            }
        }
        catch(error){
            console.log(error)
        }
    }

    const GetSingleSendToRiverlakeInternal = async(sendToRLID)=>{
        try{
            var newsession = new SendToRiverlake()
            newsession.rlSendToRiverlakeID = sendToRLID
            var response = await ApiHelper.GetSingleSendToRiverlakeInternal(newsession)
            return response

        }
        catch(e){
            console.log(e)
        }
    }
    const handleStartSession = async(exportID)=>{
        try{
            var newsession = new ExportSession()
            newsession.RLExportSessionID = exportID
            await ApiHelper.StartExportSession(newsession)
            return true

        }
        catch(e){
            console.log(e)
        }
    }
    const CreateItemToSend=async(sendToRLID)=>{
        try{
            var newsession = new SendToRiverlake()
            newsession.rlSendToRiverlakeID = sendToRLID
            var exportID = await ApiHelper.CreateItemToSend(newsession)
            return true;

        }
        catch(e){
            console.log(e)
        }
    }
    return(
        <>
        <div className="summarycontainer">
        {summarys.map((element,index)=>(
            <div key={'session'+index}>
                <div className="summaryobject">
                    <div className="summaryobjectlabel" onClick={()=>handleSummaryClick(element.exportID,index)}>
                        {summarysDetails[index].show && <KeyboardArrowUpIcon  className="summarycavert"/>}
                        {!summarysDetails[index].show && <KeyboardArrowDownIcon className="summarycavert"/>}
                        <div className="summaryobjectlabeltext">{element.label}{(element.listYear != null &&element.listYear != undefined&&element.listYear != 0)&&<span> - {element.listYear}</span>}</div>
                    </div>
                    <div className="summaryobjectbuttondiv">
                    {(element.exportStartedDate== null || element.exportStartedDate== undefined)&&
                    <>
                        <Button variant="contained" className="startexportbutton" color="error" sx={{p:'6px',width:'40px',minWidth:'40px',marginRight:'4px'}}  onClick={(e)=>handleDeleteClick(element.rlSendToRiverlakeID,index)}><DeleteIcon /></Button>
                        <Button variant="contained" className="startexportbutton" color="success" sx={{p:'6px',width:'40px',minWidth:'40px',marginRight:'4px'}}  onClick={(e)=>handleEditClick(element.rlSendToRiverlakeID,index)}><EditIcon /></Button>
                        <Button variant="contained" className="startexportbutton" onClick={(e)=>handleExport(element.rlSendToRiverlakeID,element.remoteClientID,element.exportID)}>Start Export</Button>
                        </>
                    }
                    {element.exportStartedDate && (element.exportEndedDate== null || element.exportEndedDate== undefined) &&
                        <div  className="exportstatus">In Progress</div>
                    }
                    {element.exportCompleted &&
                        <div  className="exportstatus">Completed</div>
                    }

                    </div>
                </div>
                {(summarysDetails[index].show)&&<div className='summarybreakdown'>
                    <RLLinkMiniTable uarns={element.uarn} />
                </div>}
            </div>
        ))
        }
        </div>
        {editID&&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">You have unsaved changes </div>
                <div className="confirmationmodaldiv">This will overwrite all records in the Riverlake tab.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => handleConfirmEdit(editID)}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (setEditID(undefined))}>Cancel</button>
                </div>
            </div>
        }
        {deleteID &&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">Are you sure?</div>
                <div className="confirmationmodaldiv">This will permanently delete this list.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => (handleDelete())}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (setDeleteID(undefined))}>Cancel</button>
                </div>
            </div>
        }
        </>
    )
}