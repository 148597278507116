import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridColumnGroup, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../../Models/AuthorityScheduleResults';
import { cleanup } from '@testing-library/react';
import AuthorityScheduleAdv1 from '../../Models/AuthorityScheduleAdv1';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas, formatScheduleStatus, FormatSettlementCode } from '../../Services/FormatService';
import FlagComponent from '../SubComponents/FlagComponent';
import AuthorityScheduleAdv2ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv2ApiModel';
import AuthorityScheduleAdv1ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel';
import BASchedule from '../../Models/SearchApiModels/BAScheduleModel';
import HomeIcon from '@mui/icons-material/Home';
import { setLoading } from '../../Redux/SearchSlicer';




export default function BAScheduleTable(props) {
  const {uarn} = props

  const [baScheduleData, setBAScheduleData] = useState<Array<BASchedule>>();
  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [baScheduleData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  
  async function fetchData() {
     
    try
    {
      store.dispatch(setLoading(true))
      setLocalLoading(true);
    var data;
  
        data = await ApiHelper.GetBASchedule(uarn);
        var dataarray = data ? data : [];
        data.forEach(element => 
          element.key_field = getRowId()
        );
       
           
            setBAScheduleData(dataarray);
        
            setLocalLoading(false);
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
      finally{
        store.dispatch(setLoading(false))
      }
  }
  

  const handleRowOrderChange = (model) => {
    pageNo = 0
    fetchData();
    apiRef.current.scroll({ top: 0 });
  }
  const handleRowsScrollEnd = () => {
    pageNo += 1;
    fetchData();
  }

  const handleContextMenu = (event, row) => {
     
    event.preventDefault();

    if (event.shiftKey) {
    }
    else {
      if (event.ctrlKey) {
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.keY_FIELD == elem) > -1){
        }
        else{
          setRowSelectionModel([row.keY_FIELD]);
          if (row.uarn !== selectedUARN) {

            setSelectedUARN(row.uarn);
      
          }
        }
          
      }
    }

   
  };

  const columns: GridColDef[] = [
    
    { field: 'schedulE_DATE', headerName: 'SCHEDULE DATE', type: 'date', width: 80, valueFormatter: (params) => formatDateToString(params) },
    { field: 'lisT_YEAR', headerName: 'LIST', width: 40, align:'center'},
    {
      field: 'status', headerName: 'STATUS', minWidth: 40, width: 60, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatScheduleStatus(params.value) || <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatScheduleStatus(value)
      }
    },
    { field: 'firM_NAME', headerName: 'FIRM NAME', width: 85 },
    { field: 'address', headerName: 'ADDRESS', width: 210 },
   
    { field: 'postcode', headerName: 'POSTCODE', width: 100 },
    { field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE NUMBER', width: 100},      
    { field: 'description', headerName: 'DESCRIPTION', width: 160 },

    { field: 'psD_CODE', headerName: 'PSD', width: 100, align:'center' },
    { field: 'scat', headerName: 'SCAT', width: 90, align:'center'},      
    { field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE', width: 110, align:'right',
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
        {params.value != null && FormatNumberWithCommas(params.value)}         
          {params.value == null && <span>DELETED</span>}
        </div>
      ),

     },
    { field: 'effectivE_DATE', headerName: 'EFFECTIVE DATE',  type: 'date', width: 110, valueFormatter: (params) => formatDateToString(params)  },
    { field: 'lisT_ALTERATION_DATE', headerName: 'ALTERATION DATE',  type: 'date', width: 110, valueFormatter: (params) => formatDateToString(params)  },

 
    { field: 'settlemenT_TYPE', headerName: 'SETTLEMENT', width: 100, headerAlign:  'center',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(params.row.lisT_YEAR , params.value)}</span> : <span>&nbsp;</span>}
 
        </div>
      ),
     },

     { field: 'composite', headerName: 'COMPOSITE', width: 75,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} >
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value =='C' && <HomeIcon sx={{height:18,width:18}} color='secondary' />}
          
        </div>
      ),
      },
    { field: 'voA_CASE', headerName: 'VOA CASE REFERENCE', width: 90},      
    { field: 'casE_TYPE', headerName: 'CASE TYPE', width: 75 },

    { field: 'reporT_NUMBER', headerName: 'BA REPORT NUMBER', width: 100 },
    { field: 'changE_REASON', headerName: 'CHANGE REASON', width: 130},      
    { field: 'certified', headerName: 'CERTIFICATION', width: 100,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value == 'Y ' ? <>YES</> : <span>&nbsp;</span>}
          {params.value == 'L ' ? <>INVITED</> : <span>&nbsp;</span>}
        </div>
      ),
     },
    

    ]

  const columnGroupingModel: GridColumnGroup[] = [
    
    {
      groupId: 'ADDRESS/STATUS',
      children: [{ field: 'firm_name' }, { field: 'address' }, { field: 'lisT_YEAR' }, { field: 'schedulE_DATE' }, { field: 'postcode' }, { field: 'status' }, { field: 'firM_NAME' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'bA_REFERENCE_NUMBER' }, { field: 'description' }, { field: 'psD_CODE' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    
   
    {
      groupId: 'INFORMATION SENT TO BILLING AUTHORITY',
      children: [{ field: 'rateablE_VALUE' }, { field: 'effectivE_DATE' }, { field: 'lisT_ALTERATION_DATE' },  { field: 'composite' }, { field: 'voA_CASE' }, { field: 'casE_TYPE' }, { field: 'reporT_NUMBER' }, { field: 'changE_REASON' },  { field: 'certified' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]
  
  const rows = baScheduleData || [];

  if (localLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
  return (
    
    <div style={{height: '100%', width:'100%'}}>   
      {rows && rows.length > 0 ? (
        <DataGridPro rows={rows} columns={columns} rowHeight={22}  columnHeaderHeight={22}
          
          sortingOrder={['asc', 'desc']}
          getRowId={(row: BASchedule) => row.key_field!}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          columnGroupingModel={columnGroupingModel}
          apiRef={apiRef}
          hideFooter = {true}
          autosizeOptions={{
            includeOutliers: false,
            includeHeaders: false,
          }}
        />
      ): (<>
        {baScheduleData !=undefined && <p>No data available</p>}
      </>)

      }  
   
   
    </div>
  );
}