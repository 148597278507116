import { TocTwoTone } from "@mui/icons-material";
import { getAuthenticationCookies, getCookie, setAuthCookies, setLoginCookies } from "../Services/CookieService";
import { UserTokens } from "../Models/UserTokens";
import { refreshTokens } from "./APIHelper";
import  store  from '../Redux/ConfigureStore';
import { logout, logoutMessage } from "../Redux/AuthorisationSlicer";
import { LOGOUT } from "../Redux/Actions";

var pendingRefresh = false;

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}
interface HttpResponse<T> extends Response {
    parsedBody?: T;
  }
  
  export async function http<T>(
    request: RequestInfo
  ): Promise<HttpResponse<T>> {
    let response: HttpResponse<T>;
  
    try {
      response = (await fetch(request)) as HttpResponse<T>;
  
      try {
        response.parsedBody = await response.json();
      } catch (e) {
        console.error("Failed to parse JSON:", e);
        response.parsedBody = undefined;
      }

      if (response.status === 403) {
        if (response.parsedBody) {
          store.dispatch({ type: LOGOUT,message: response.parsedBody,hasNoTokens:true})
        }
      }
    } catch (err) {
      console.error("Fetch error:", err);
        throw err;
    }
  
    return response;
  }

export async function httpStream(
    request: RequestInfo
): Promise<HttpResponse<string>> {
    const response: HttpResponse<string> = await fetch(request);  
    if (response.status === 403) {
        response.parsedBody = await response.json();
        if (response.parsedBody) {
          
          store.dispatch({ type: LOGOUT,message: response.parsedBody,hasNoTokens:true})
        }
      }
    if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';
        let done = false;

        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            result += decoder.decode(value, { stream: !done });
        }

        response.parsedBody = result
    } else {
        response.parsedBody = undefined; 
    }
    console.log(response)
    return response;
}


export async function get<T>(
    path: string,
    args: RequestInit = { method: "get" }
): Promise<T | undefined> {
    var fetchPromise = await http<T>(new Request(path, args));
    try {
        fetchPromise.parsedBody = await fetchPromise.json();
    } catch (ex) { }
    if (!fetchPromise.ok) {
        throw new Error(fetchPromise.statusText);
    }
    return fetchPromise.parsedBody;
};

export async function post<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: "post", headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json'
        }, body: JSON.stringify(body)
    }
): Promise<T | undefined> {
    var fetchPromise = await http<T>(new Request(path, args));

    try {

        fetchPromise.parsedBody = await fetchPromise.json();
    } catch (ex) { console.log(ex)}

    if (!fetchPromise.ok) {
        throw new Error(fetchPromise.statusText);
    }
    return fetchPromise.parsedBody;
};

export async function put<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: "put", headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json'
        }, body: JSON.stringify(body)
    }
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};


export async function protectedhttp<T>(
    request: Request
): Promise<HttpResponse<T>> {
    if(pendingRefresh == true){
        await delay(2000)
    }
    var response: HttpResponse<T> = await fetch(request.clone());
    var tokens
    if (response.status === 401) {
        if(pendingRefresh == false){
            pendingRefresh = true;
            try{
            tokens = await refreshTokens(getAuthenticationCookies());
            }
            catch{
                if(store.getState().authorisation.authenticated){
                    store.dispatch({ type: LOGOUT, hasNoTokens:true  })
                }

            }finally{
                if (tokens==undefined) {
                    if(store.getState().authorisation.authenticated){
                        store.dispatch({ type: LOGOUT, hasNoTokens:true })
                    }
                }
                else{
                    setAuthCookies(tokens);
                }
                pendingRefresh = false
            }
        }
        else{
            while (pendingRefresh) {
                  await delay(1000); 
              }
        } 
            let modifiedHeaders = new Headers(request.headers);
            
            modifiedHeaders.set('Authorization', 'Bearer ' +getCookie('token'));
            
            let newRequest = new Request(request, {
                headers: modifiedHeaders
            });
            
            response = await fetch(newRequest);
            
            if (response.status === 401) {
                if(store.getState().authorisation.authenticated){
                    store.dispatch({ type: LOGOUT,hasNoTokens: true })
                }
            }
        
    }
    try{
    response.parsedBody = await response.json();
    }catch(e){
        console.error(e)
    }
    return response;
}
export async function protectedGet<T>(
    path: string,
    args: RequestInit = { method: "get", headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+getCookie('token'),
    }, }
): Promise<T | undefined> {
    var fetchPromise = await protectedhttp<T>(new Request(path, args));
    try {
        fetchPromise.parsedBody = await fetchPromise.json();
    } catch (ex) { }
    if (!fetchPromise.ok) {
        throw new Error(fetchPromise.statusText);
    }
    return fetchPromise.parsedBody;
};

export async function protectedPost<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: "post", headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+getCookie('token'),
        }, body: JSON.stringify(body)
    }
): Promise<T | undefined> {
    var fetchPromise = await protectedhttp<T>(new Request(path, args));

    if (!fetchPromise.ok) {
        throw new Error(fetchPromise.statusText);
    }
    return fetchPromise.parsedBody;
};

export async function protectedPut<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: "put", headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+getCookie('token'),
        }, body: JSON.stringify(body)
    }
): Promise<HttpResponse<T>> {
    return await protectedhttp<T>(new Request(path, args));
};

export async function protectedReportPost(
    path: string,
    body: any,
    args: RequestInit = {
        method: "post", headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+getCookie('token'),
        }, body: JSON.stringify(body)
    }
): Promise<string> {
    var tokens
    var request = new Request(path, args)
    var fetchPromise;
    fetchPromise= await httpStream(request.clone());
    if(fetchPromise.status === 401){
        if(pendingRefresh == false){
        pendingRefresh = true;
        try{
        tokens = await refreshTokens(getAuthenticationCookies());
        }
        catch(e){
            console.log(e)

        }finally{
            if (tokens==undefined) {
                if(store.getState().authorisation.authenticated){
                    store.dispatch({ type: LOGOUT, hasNoTokens:true })
                }
            }
            else{
                setAuthCookies(tokens);
            }
            pendingRefresh = false
        }
    }
    else{
        await delay(1000)
    } 
        let modifiedHeaders = new Headers(request.headers);
        
        modifiedHeaders.set('Authorization', 'Bearer ' +getCookie('token'));
        
        let newRequest = new Request(request, {
            headers: modifiedHeaders
        });
        
        fetchPromise = await httpStream(newRequest);;
        
        if (fetchPromise.status === 401) {
            if(store.getState().authorisation.authenticated){
                store.dispatch({ type: LOGOUT,hasNoToken: true })
            }
        }
    }

    if(fetchPromise.parsedBody){
        return fetchPromise.parsedBody;
    }
    else{
        return ''
    }
};
function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  