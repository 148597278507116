import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { DataGridPro, useGridApiRef, GridPinnedColumnFields } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import {
  resetRiverlakeTableSort,
  selectPinColumns,
  selectResetRiverlakeTableSort,
} from "../../Redux/RiverlakeSlicer";
import store from "../../Redux/ConfigureStore";
import RiverlakeRightClickMenu from "./TableContextMenus/RiverlakeRightClickMenu";
import { setRiverlakeSettings, setViewId } from "../../Redux/UserSlicer";
import { UserViewSettings } from "../../Models/UserViewSettings";
import { setLoading, setSelectedUARN } from "../../Redux/SearchSlicer";
import * as ApiHelper from "../../API/AnalyseAPIHelper";

interface ChildProps {
  handleVisibilityChange: (params: any) => void;
  intListVisibility: any;
  columnGroupingModel: any;
  handleCloseContextMenu: (params: any) => void;
  handleColumnWidthChange: (params: any) => void;
  contextMenuPosition: any;
  handleMenuItemClick: (params: any) => void;
  rows: any;
  rowSelectionModel: any;
  setRowSelectionModel: (params: any) => void;
  handleRowClick: (params: any) => void;
  setTableState: (params: any) => void;
  tableState: any;
  triggerSaveTableState: boolean;
  columns: any;
  setContextMenuPosition:(params:any)=>void;
}

export interface RiverlakeTableRef {
  handleThisExportModel: (params?: any) => void;
}

export const RiverlakeTable = forwardRef<RiverlakeTableRef, ChildProps>(
  (
    {
      handleVisibilityChange,
      intListVisibility,
      contextMenuPosition,
      handleCloseContextMenu,
      handleColumnWidthChange,
      handleMenuItemClick,
      columnGroupingModel,
      rows,
      rowSelectionModel,
      setRowSelectionModel,
      handleRowClick,
      setTableState,
      tableState,
      triggerSaveTableState,
      columns,
      setContextMenuPosition,
    },
    ref
  ) => {
    
    const pinColumns = useSelector(selectPinColumns);
    const resetTableSort = useSelector(selectResetRiverlakeTableSort);
    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields>();
    const apiRef = useGridApiRef()
    useImperativeHandle(ref, () => ({
      handleThisExportModel(param?) {
        handleExportModel(param);
      },
      apiRef
    }));

    useEffect(() => {
      if (tableState && apiRef.current) {
        const { columns, ...restOfState } = tableState; 
        apiRef.current.restoreState(restOfState); 
        console.log(tableState)
      }
    }, [tableState]);



    const handleExportModel = (params?: any) => {
      if (apiRef.current && Object.keys(apiRef.current).length !== 0) {
        const exportedColumns = apiRef.current.exportState()?.columns;
        if (exportedColumns) {
          exportedColumns.columnVisibilityModel = params || exportedColumns.columnVisibilityModel;
          const newString = JSON.stringify(exportedColumns);

          store.dispatch(
            setRiverlakeSettings({
              dimensions: exportedColumns.dimensions,
              orderedFields: exportedColumns.orderedFields,
              columnVisibilityModel: exportedColumns.columnVisibilityModel,
              riverlakeDbString: newString,
            })
          );

        }
      }
    };

    useEffect(() => {
      if (triggerSaveTableState && apiRef.current) {
        setTableState(apiRef.current.exportState());
      }
    }, [triggerSaveTableState]);

    useEffect(() => {
      if (resetTableSort) {
        store.dispatch(resetRiverlakeTableSort(false));
        apiRef.current?.setSortModel([]);
      }
    }, [resetTableSort]);

    useEffect(() => {
      if (pinColumns) {
        setPinnedColumns({
          left: [
            "flaG_2023",
            "flaG_2017",
            "flaG_2010",
            "flaG_2005",
            "flaG_2000",
            "flaG_1995",
            "flaG_1990",
            "firm_name",
            "address",
            "postcode",
          ]
        });
      } else {
        setPinnedColumns({ left: []})
    }}, [pinColumns]);
    const handleContextMenu = (event) => {
      event.preventDefault();
      var row = rows.find(elem=>elem.keY_FIELD == Number(event.currentTarget.getAttribute('data-id')))
  
      if (event.shiftKey) {
          setRowSelectionModel([...rowSelectionModel,row!.keY_FIELD!]);
      }
      else {
        if (event.ctrlKey) {
          if (rowSelectionModel.findIndex(elem => row!.keY_FIELD == elem) > -1) {
          }
          else {
            setRowSelectionModel([...rowSelectionModel,row!.keY_FIELD!]);
          }
        }
        else {
          if (rowSelectionModel.findIndex(elem => row!.keY_FIELD == elem) > -1) {
          }
          else {
            setRowSelectionModel([row!.keY_FIELD!]);
          }
  
        }
      }
  
      if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
        handleCloseContextMenu(() => {
          //setSelectedRow(row);
          setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
        });
      } else {
        //setSelectedRow(row);
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      }
      store.dispatch(setSelectedUARN(row!.uarn));
    };
    
    const onClipboardCopy = (data: any) => {
      const visibleColumns = apiRef.current?.getVisibleColumns();
      const columnsAsString = visibleColumns
        ?.filter((col) => col.field !== "__check__")
        .map((col) => col.headerName)
        .join("\t");
      navigator.clipboard.writeText(columnsAsString + "\n" + data);
    };

    return (
      <>
        <DataGridPro
          columns={columns}
          rowHeight={22}
          rows={rows}
          apiRef={apiRef}
          columnHeaderHeight={22}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
          onClipboardCopy={onClipboardCopy}
          getRowId={(row: any) => row.keY_FIELD}
          columnGroupingModel={columnGroupingModel}
          onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)}
          onColumnVisibilityModelChange={(visModel) => handleVisibilityChange(visModel)}
          onColumnOrderChange={() => handleExportModel()}
          columnVisibilityModel={intListVisibility}
          pinnedColumns={pinnedColumns}
          pagination={false}
          onRowClick={handleRowClick}
          rowSelectionModel={rowSelectionModel}
          onColumnWidthChange={handleColumnWidthChange}
          hideFooter          
          slotProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: 'context-menu' },
            },
          }}
          />
        <RiverlakeRightClickMenu
          contextMenuPosition={contextMenuPosition}
          handleClose={handleCloseContextMenu}
          handleMenuItemClick={handleMenuItemClick}
        />
      </>
    );
  }
);