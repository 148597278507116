export default class SendToRiverlake{
    customerID?: string;
    uarn?: string;
    label?: string;
    remoteClientID?:number;
    rlSendToRiverlakeID?:number;
    exportID?:number;
    exportStartedDate?:Date;
    exportEndedDate?: Date;
    exportCompleted?: boolean;
    listYear?:number;
  }