import { Menu, MenuItem } from "@mui/material";

const RightClickOptions = {
  DeleteAssessments: { id: 0, label: "Delete Assessment(s)", icon: "deleterowicon" },
 }
export default function RiverlakeRightClickMenu({ contextMenuPosition, handleClose, handleMenuItemClick  }) {

    const isContextMenuOpen = contextMenuPosition.mouseY !== null && contextMenuPosition.mouseX !== null;
  
    const handleMenuClose = () => {
      handleClose();
    };
  
    const handleMenuClick = (item) => {
      handleMenuItemClick(item);
      handleClose();
    };
    const handleBackgroundRightClick = (event)=>{
      handleClose();
    }
    
    return (<>

      <Menu
        open={isContextMenuOpen}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          isContextMenuOpen
            ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
            : undefined
        }
        MenuListProps={{
          onClick: (event) => event.stopPropagation(), 
          onContextMenu: (event) => {
            event.preventDefault(); 
            handleMenuClose(); 
          },
        }}
      >
          {Object.values(RightClickOptions).map((element) => (                        
              <MenuItem sx={{p:'0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
                <span className={"rightclickicon "+element.icon}></span>
                <span className="rightclicktext">{element.label}</span>
              </MenuItem>                
          ))}
      </Menu>
      </>
    );
  };
  