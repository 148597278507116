import * as React from 'react';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearch, SelectIsLoading, selectTableContext } from '../Redux/SearchSlicer';
import store from '../Redux/ConfigureStore';
import RouteComponent from '../Component/RouteComponent';
import { LicenseInfo } from '@mui/x-license';
import * as ApiHelper from '../API/AnalyseAPIHelper';
import { setBAListDropdown, setListDropDown, setLocalVTODropdown, setPSDDropDown, setSCATDropDown, setScheduleDatesDropDown, setSettlement1990, setSettlement1995, setSettlement2000, setSettlement2005, setSettlement2010, setSettlement2017, setSettlement2023, setVOANNDRRVUDropdown, setVTODropdown } from '../Redux/PreloadedSlicer';
import HeaderBarHandlerComponent from '../Component/HeaderBarComponents/HeaderBarHandlerComponent';

import ListBuilderComponent from '../Component/ListBuilderComponent';
import { selectHasUnsavedChanges } from '../Redux/ListBuilderSlicer';
import { getConfig } from '../API/AnalyseAPIHelper';
import { Button } from '@mui/material';
import { LOGOUT } from '../Redux/Actions';
import {  setSettingsFromDBObject, setViewArray } from '../Redux/UserSlicer';
import PersonalisedViewsComponent from './TableComponents/PersonalisedViewsComponent';
import { getCookie } from '../Services/CookieService';
import RiverlakeComponent from './RiverlakeComponent';
import { selectIsRiverlakeUser } from '../Redux/AuthorisationSlicer';
import { selectRLHasUnsavedChanges } from '../Redux/RiverlakeSlicer';
import { LogoutAPI } from '../API/APIHelper';

LicenseInfo.setLicenseKey('7329c85744e7152b2fd9531f9b9e0db0Tz04OTAwMyxFPTE3NDU1NzY0NzIwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');
export default function AnalyseComponent() {
  const[searchCount,setSearchCount] = useState<number|null>(0);
  const tableContext = useSelector(selectTableContext);
  //const[sqlDump,setSQLDump] = useState(false);
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);
  const rlHasUnsavedChanges = useSelector(selectRLHasUnsavedChanges)
  const isLoading = useSelector(SelectIsLoading)
  const dispatch = useDispatch();
  const isNavigatingAway = useRef(false);
  const isRiverlakeUser = useSelector(selectIsRiverlakeUser)


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isNavigatingAway.current && hasUnsavedChanges ) {
        var list = [...store.getState().listBuilder.listList];
        list.sort();
        var savedList = [...store.getState().listBuilder.savedList];
        if( JSON.stringify(list) != JSON.stringify(savedList)){
          event.preventDefault();
          event.returnValue = 'Unsaved changes may be lost'; 

        }
      }
      if (!isNavigatingAway.current && rlHasUnsavedChanges ) {
        var list = [...store.getState().riverlake.listList];
        list.sort();
        var savedList = [...store.getState().riverlake.savedList];
        savedList.sort()
        if( JSON.stringify(list) != JSON.stringify(savedList)){
          event.preventDefault();
          event.returnValue = 'Unsaved changes may be lost'; 

        }
      }
      if (!isNavigatingAway.current){
        store.dispatch({ type: LOGOUT })
      }
    };

    const handlePageHide = (event) => {
      if (event.persisted) {
        isNavigatingAway.current = false;
      } else {
        isNavigatingAway.current = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handlePageHide);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, [hasUnsavedChanges]); 


  useEffect(()=>{
    initialiseApp()

    
  },[]);
  async function initialiseApp(){
    await getConfig();
    getPreloadedData();
  }
  async function getPreloadedData(){
    try {
      var data = await ApiHelper.GetPSDCode();
      var dataarray = data ? data : [];
      store.dispatch(setPSDDropDown(dataarray));
    }catch(error){
      console.log(error)
    }
      try {
        var data = await ApiHelper.GetSCATCode();
        var dataarray = data ? data : [];
        store.dispatch(setSCATDropDown(dataarray));
      }catch(error){
        console.log(error)
      }
      try {
        var listdata = await ApiHelper.GetListYears();
        var listdataarray = listdata ? listdata : [];
        store.dispatch(setListDropDown(listdataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var scheduledata = await ApiHelper.GetScheduleDates();
        var scheduledataarray = scheduledata ? scheduledata : [];
        store.dispatch(setScheduleDatesDropDown(scheduledataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var baListdata = await ApiHelper.GetBAList();
        var baListdataarray = baListdata ? baListdata : [];
        store.dispatch(setBAListDropdown(baListdataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var localVOAdata = await ApiHelper.GetLocalVOAList();
        var localVOAdataarray = localVOAdata ? localVOAdata : [];
        store.dispatch(setLocalVTODropdown(localVOAdataarray));
      }catch(error){
        console.log(error)
      }  
      try {
        var VOANNDRRVUdata = await ApiHelper.GetVOANNDRRVUList();
        var VOANNDRRVUdataarray = VOANNDRRVUdata ? VOANNDRRVUdata : [];
        store.dispatch(setVOANNDRRVUDropdown(VOANNDRRVUdataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var VTOListdata = await ApiHelper.GetVTOList();
        var VTOListdataarray = VTOListdata ? VTOListdata : [];
        store.dispatch(setVTODropdown(VTOListdataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settlement2023data = await ApiHelper.GetSettlementCode(2023);
        var settlement2023dataarray = settlement2023data ? settlement2023data : [];
        store.dispatch(setSettlement2023(settlement2023dataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settlement2017data = await ApiHelper.GetSettlementCode(2017);
        var settlement2017dataarray = settlement2017data ? settlement2017data : [];
        store.dispatch(setSettlement2017(settlement2017dataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settlement2010data = await ApiHelper.GetSettlementCode(2010);
        var settlement2010dataarray = settlement2010data ? settlement2010data : [];
        store.dispatch(setSettlement2010(settlement2010dataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settlement2005data = await ApiHelper.GetSettlementCode(2005);
        var settlement2005dataarray = settlement2005data ? settlement2005data : [];
        store.dispatch(setSettlement2005(settlement2005dataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settlement2000data = await ApiHelper.GetSettlementCode(2000);
        var settlement2000dataarray = settlement2000data ? settlement2000data : [];
        store.dispatch(setSettlement2000(settlement2000dataarray));
      }catch(error){
        console.log(error)
      }  
      try {
        var settlement1995data = await ApiHelper.GetSettlementCode(1995);
        var settlement1995dataarray = settlement1995data ? settlement1995data : [];
        store.dispatch(setSettlement1995(settlement1995dataarray));
      }catch(error){
        console.log(error)
      }  
      try {
        var settlement1990data = await ApiHelper.GetSettlementCode(1990);
        var settlement1990dataarray = settlement1990data ? settlement1990data : [];
        store.dispatch(setSettlement1990(settlement1990dataarray));
      }catch(error){
        console.log(error)
      } 
      try {
        var settingData = await ApiHelper.GetAllViews();
        if(settingData && settingData.length > 0){
          var viewID = getCookie('currentView')
          if(viewID && Number(viewID)>0){
            var found= settingData.find(elem=>elem.viewId == Number(viewID))
            if(found){
              store.dispatch(setSettingsFromDBObject(found));

            }
          }
          
          store.dispatch(setViewArray(settingData));
        }
      }catch(error){
        console.log(error)
      } 
    }

  const handleSearchClick=(e)=>{
    if(searchCount){
      setSearchCount(searchCount+1)
    }
    else{
      setSearchCount(1)
    }
  }
  const  handleClearClick=async(e)=>{
    await store.dispatch(clearSearch(null));
    if(searchCount!=0){
      setSearchCount(0);
    }
    else{
      setSearchCount(null);
    }
  }
const handleLogout=()=>{
  dispatch({ type: LOGOUT })
}
  useEffect(() => {
    setSearchCount(0);
  }, [tableContext]);
  return (<>
      <HeaderBarHandlerComponent searchCount ={searchCount} handleSearchClick = {handleSearchClick} handleClearClick = {handleClearClick}/>
      <div className={'appcontent'}>
        <RouteComponent searchCount ={searchCount} handleSearchClick = {handleSearchClick}/>
        {/* <input className='sqldump' type="checkbox" checked={sqlDump} onChange={handleCheckBoxChange}/> */
        }
        <PersonalisedViewsComponent />
        <Button variant='contained' sx={{
        '&:hover': {
          backgroundColor: '#009FE3 !important', 
        },
      }} className='logout' onClick={()=>handleLogout()}> Logout</Button>
      </div> 
      <ListBuilderComponent/>
      {isRiverlakeUser &&<RiverlakeComponent/>}
      {isLoading &&<div className='loadingblocker'></div>}
      </>
  );
}