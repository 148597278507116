import { Password } from "@mui/icons-material";
import { authenticated, login, resetLogoutMessage, selectLogoutMessage } from "../Redux/AuthorisationSlicer";
import store from "../Redux/ConfigureStore";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as ApiHelper from '../API/APIHelper';
import { UserLoginModel } from "../Models/UserLoginModel";
import { Button, Checkbox, ClickAwayListener, Snackbar } from "@mui/material";
import { getAuthenticationCookies } from "../Services/CookieService";
import MuiAlert, { AlertColor } from '@mui/material/Alert';


export default function LoginComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const isAuthenticated = useSelector(authenticated);
    const logoutMessage = useSelector(selectLogoutMessage);
    const [loginFailed,SetLoginFailed] = useState(false);
    const navigate = useNavigate();
    const handleLogin = async () => {
        var loginModel = new UserLoginModel()
        store.dispatch(resetLogoutMessage())
        var response
        if(username !=''&& password !=''){
            try {
                loginModel.username = username
                loginModel.password = password
                response = await ApiHelper.loginUser(loginModel)
                store.dispatch(login(response))
            }
            catch (e) {
                console.error(e)
                console.error('login fail')
                SetLoginFailed(true);
            }
        }
        else{
            if(username ==''){
                setInvalidUsername(true)
            }
            if(password ==''){
                setInvalidPassword(true)
            }
        }
    }
    useEffect(()=>{
        setInvalidPassword(false)
    },[password])

    useEffect(()=>{
        setInvalidUsername(false)
    },[username])

    const handleForgotPassword = ()=>{
        navigate("/forgot-password");
    }
    useEffect(()=>{

        if (isAuthenticated) {
            return navigate("/") ;
        }
    },[isAuthenticated])


    return (
        <>
            <div className='logincomponent' onKeyDown={(e) => e.key === 'Enter' && handleLogin()}>
                <div className="analyselogo">
                </div>
                <div className="logininputdiv layoutcolumn">
                    <label htmlFor="username" className="loginlabel"> Username</label>
                    <input type='text' name="username" id="username" className="loginfield" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                    {invalidUsername&&<div className="loginerrormessage">Please enter a username.</div>}
                </div>
                <div className="logininputdiv layoutcolumn">
                    <label htmlFor="passwword" className="loginlabel"> Password</label>
                    <input value={password} type='password' name='password' id="password" className="loginfield"  onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    {invalidPassword&&<div className="loginerrormessage">Please enter a Password.</div>}
                </div>
                <div className="loginadditionalclicks layoutrow">

                    <div className="remembermediv layoutrow">
                        <Checkbox color="secondary" sx={{ p: 0, }} name='rememberme' checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                        <div className="keepmesignedin">Keep me signed in</div>
                    </div>
                    <div className="forgotpassworddiv">
                        <div className="forgotpassword" onClick={()=>handleForgotPassword()}>Forgot Password?</div>
                    </div>
                </div>
                <Button type="submit" variant="contained" sx={{ marginTop:'40px', bgcolor: 'lightblue.main',fontSize:'18px'}} className="loginbutton" onClick={() => handleLogin()}>Login</Button>
                {loginFailed&&<div className="loginerrormessage">Login Failed!</div>}
                {logoutMessage&&<div className="loginerrormessage">{logoutMessage}</div>}
                
            </div>

                </>

    )
}