import {protectedGet, protectedPost, protectedReportPost } from './CommonApiHelper';
import AuthorityScheduleSearch from '../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../Models/AuthorityScheduleResults';
import IntegratedSearch from '../Models/SearchApiModels/IntegratedSearch';
import IntegratedListResult from '../Models/IntegratedListResult';
import SummaryValuationSearch from '../Models/SearchApiModels/SummaryValuationSearch';
import SummaryValuationResults from '../Models/SummaryValuationResults';
import ValuationTribunalSearch from '../Models/SearchApiModels/ValuationTribunalSearch';
import ValuationTribunalResults from '../Models/ValuationTribunalResults';
import BasicCodeModel from '../Models/BasicCodeModel';
import ListBuilderRow from '../Models/ListBuilderRow';
import RvHistoryGraph from '../Models/RvHistoryGraph';
import ListBuilderMetaData from '../Models/ListBuilderMetaData';
import ListBuilderModel from '../Models/ListBuilderModel';
import { ReportModel } from '../Models/ReportModel';
import { VTSReportModel } from '../Models/VTSReportModel';
import { VONoticeReportModel } from '../Models/VONoticeReportModel';
import BASchedule from '../Models/SearchApiModels/BAScheduleModel';
import DirectoryBA from '../Models/SearchApiModels/DirectoryBAModel';
import SummaryValuation from '../Models/SearchApiModels/SummaryValuation';
import SVSurveyPieChart from '../Models/SVSurveyPieChart';
import SchemeModel from '../Models/SchemeModel';
import IntegratedListExcel from '../Models/IntegratedListExcel';
import AuthorityScheduleExcel from '../Models/AuthorityScheduleExcel';
import SummaryValuationExcel from '../Models/SummaryValuationExcel';
import ValuationTribunalExcel from '../Models/ValuationTribunalExcel';
import DARReportModel from '../Models/DARReportModel';
import { UserViewSettings } from '../Models/UserViewSettings';
import SendToRiverlake from '../Models/SendToRiverLake';
import ExportSession from '../Models/ExportSession';
import { RLClient } from '../Models/RLClient';
import SendToRiverlakeDetails from '../Models/SendToRiverlakeDetails';
import { UserAdmin } from '../Models/UserAdmin';

let setAPIURL="";
let setUserID="";
export async function getConfig() {

    let config = "";
    await fetch("./settings.json", {
        method: "GET",
    })
        .then((response) => response.json())
        .then((json) => {
            config = json.appSettings.API;
            setAPIURL = config
        })
}
   
export async function GetAuthoritySchedule(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthoritySchedule/";
    return await protectedPost<Array<AuthorityScheduleResults>>((setAPIURL+endpoint),query);

}
   
export async function GetAuthorityScheduleCount(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthorityScheduleCount/";
    return await protectedPost<Array<AuthorityScheduleResults>>((setAPIURL+endpoint),query);

}
   
export async function GetAuthorityScheduleSum(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthorityScheduleSum/";
    return await protectedPost<Array<AuthorityScheduleResults>>((setAPIURL+endpoint),query);

}
  
export async function GetAuthorityScheduleReport(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthorityScheduleReport/";
    return await protectedPost<Array<AuthorityScheduleResults>>((setAPIURL+endpoint),query);

}

export async function GetIntegratedList(query : IntegratedSearch){
    var endpoint = "api/Search/GetIntegratedList/";
    return await protectedPost<Array<IntegratedListResult>>((setAPIURL+endpoint),query);

}

export async function GetIntegratedListCount(query : IntegratedSearch){
    var endpoint = "api/Search/GetIntegratedListCount/";
    return await protectedPost<Array<IntegratedListResult>>((setAPIURL+endpoint),query);

}

export async function GetIntegratedListSum(query : IntegratedSearch){
    var endpoint = "api/Search/GetIntegratedListSum/";
    return await protectedPost<Array<IntegratedListResult>>((setAPIURL+endpoint),query);

}


export async function GetSummaryValuation(query : SummaryValuationSearch){
    var endpoint = "api/Search/GetSummaryValuation/";
    return await protectedPost<Array<SummaryValuationResults>>((setAPIURL+endpoint),query);

}

export async function GetSummaryValuationSum(query : SummaryValuationSearch){
    var endpoint = "api/Search/GetSummaryValuationSum/";
    return await protectedPost<Array<SummaryValuationResults>>((setAPIURL+endpoint),query);

}

export async function GetSummaryValuationCount(query : SummaryValuationSearch){
    var endpoint = "api/Search/GetSummaryValuationCount/";
    return await protectedPost<Array<SummaryValuationResults>>((setAPIURL+endpoint),query);

}

export async function GetSummaryValuationReport(query : SummaryValuationSearch){
    var endpoint = "api/Search/GetSummaryValuationReport/";
    return await protectedPost<Array<SummaryValuationResults>>((setAPIURL+endpoint),query);

}

export async function GetValuationTribunal(query : ValuationTribunalSearch){
    var endpoint = "api/Search/GetValuationTribunal/";
    return await protectedPost<Array<ValuationTribunalResults>>((setAPIURL+endpoint),query);

}

export async function GetValuationTribunalSum(query : ValuationTribunalSearch){
    var endpoint = "api/Search/GetValuationTribunalSum/";
    return await protectedPost<Array<ValuationTribunalResults>>((setAPIURL+endpoint),query);

}

export async function GetValuationTribunalCount(query : ValuationTribunalSearch){
    var endpoint = "api/Search/GetValuationTribunalCount/";
    return await protectedPost<Array<ValuationTribunalResults>>((setAPIURL+endpoint),query);

}

export async function GetValuationTribunalReport(query : ValuationTribunalSearch){
    var endpoint = "api/Search/GetValuationTribunalReport/";
    return await protectedPost<Array<ValuationTribunalResults>>((setAPIURL+endpoint),query);

}


export async function GetAuthorityScheduleDump(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthoritySchedule/";
    return await protectedPost<string>((setAPIURL+"api/Search/GetAuthoritySchedule/"),query);

}
export async function GetIntegratedListDump(query : IntegratedSearch){
    var endpoint = "api/Search/GetIntegratedList/";
    return await protectedPost<string>((setAPIURL+"api/Search/GetIntegratedList/"),query);

}

export async function GetSummaryValuationDump(query : SummaryValuationSearch){
    return await protectedPost<string>((setAPIURL+"api/Search/GetSummaryValuation/"),query);

}
export async function GetValuationTribunalDump(query : ValuationTribunalSearch){
    return await protectedPost<string>((setAPIURL+"api/Search/GetValuationTribunal/"),query);

}

export async function GetPSDCode(){
    var endpoint = "api/Values/GetPSDCode/";
    var querystring = '';
    //return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring));

}
export async function GetSCATCode(){
    var endpoint = "api/Values/GetSCATCode/";
    var querystring = '';
    //return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring));

}
export async function GetListYears(){
    var endpoint = "api/Values/GetListYears/";
    var querystring = '';
    //return await protectedGet<Array<number>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<number>>((setAPIURL+endpoint+querystring));

}
export async function GetScheduleDates(){
    var endpoint = "api/Values/GetScheduleDates/";
    var querystring = '';
    //return await protectedGet<Array<string>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<string>>((setAPIURL+endpoint+querystring));

}
export async function GetBAList(){
    var endpoint = "api/Values/GetBAList/";
    var querystring = '';
    //return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring));
}
export async function GetVOANNDRRVUList(){
    var endpoint = "api/Values/GetVOANNDRRVUList/";
    var querystring = '';
    //var data =await protectedGet<Array<string>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    var data =await protectedGet<Array<string>>((setAPIURL+endpoint+querystring));
    if(data){
        return await data.map(item => ({ label: item, value: item }))
    }
    else{
        return null
    }
}
export async function GetLocalVOAList(){
    var endpoint = "api/Values/GetLocalVOAList/";
    var querystring = '';
    //var data =await protectedGet<Array<string>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    var data =await protectedGet<Array<string>>((setAPIURL+endpoint+querystring));
    if(data){
        return await data.map(item => ({ label: item, value: item }))
    }
    else{
        return null
    }
}
export async function GetVTOList(){
    var endpoint = "api/Values/GetVTOList/";
    var querystring = '';
    //var data = await protectedGet<Array<string>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    var data = await protectedGet<Array<string>>((setAPIURL+endpoint+querystring));
    if(data){
        return await data.map(item => ({ label: item, value: item }))
    }
    else{
        return null
    }
}
export async function GetSettlementCode(listYear){
    var endpoint = "api/Values/GetSettlementCode";
    var querystring ="?listYear="+Number(listYear);
    //return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring));

}
export async function GetScheme(listYear,uarn){
    var endpoint = "api/Values/GetScheme";
    var querystring ="?list="+Number(listYear)+"&uarn="+uarn;
    //return await protectedGet<Array<BasicCodeModel>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<SchemeModel>((setAPIURL+endpoint+querystring));

}
export async function GetIntegratedListByUARN(uarn){
    var endpoint = "api/details/GetIntegratedList";
    var querystring ="?uarn="+Number(uarn);
    //return await protectedGet<Partial<ListBuilderRow>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Partial<ListBuilderRow>>((setAPIURL+endpoint+querystring));

}


export async function GetRvHistory(uarn){
    var endpoint = "api/Details/GetRVHistory";
    var querystring ="?uarn="+Number(uarn);
    //return await protectedGet<Array<IntegratedListResult>>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<Array<IntegratedListResult>>((setAPIURL+endpoint+querystring));
}

export async function GetRvHistoryGraph(uarn){
    var endpoint = "api/Details/GetRVHistoryGRAPH";
    var querystring ="?uarn="+Number(uarn);
    //return await protectedGet<RvHistoryGraph>((setAPIURL+endpoint+querystring),endpoint,querystring);
    return await protectedGet<RvHistoryGraph>((setAPIURL+endpoint+querystring));

}

export async function GetIntegratedListReport(query : IntegratedSearch){
    var endpoint = "ReportsPresentation/IntegratedList";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetIntegratedListReportSelected(query : IntegratedSearch){
    var endpoint = "ReportsPresentation/IntegratedListSelected";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetDARReport(query : DARReportModel){
    var endpoint = "ReportsPresentation/DAR";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetValuationListReport(query: SummaryValuationSearch){
    var endpoint = "ReportsPresentation/ValuationList";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetValuationListReportSelected(query: SummaryValuationSearch){
    var endpoint = "ReportsPresentation/ValuationListSelected";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetVONoticeReport(query: VONoticeReportModel){
    var endpoint = "ReportsPresentation/VONotice";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetVTSReport(query: VTSReportModel){
    var endpoint = "ReportsPresentation/VTS";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetSMVReport(query: ReportModel){
    var endpoint = "ReportsPresentation/SMV";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetVTSListReport(query: ValuationTribunalSearch){
    var endpoint = "ReportsPresentation/VTSList";
    return await protectedReportPost((setAPIURL+endpoint),query);
}

export async function GetVTSListReportSelected(query: ValuationTribunalSearch){
    var endpoint = "ReportsPresentation/VTSListSelected";
    return await protectedReportPost((setAPIURL+endpoint),query);
}
export async function GetScheduleListReport(query: AuthorityScheduleSearch){
    var endpoint = "ReportsPresentation/ScheduleList";
    return await protectedReportPost((setAPIURL+endpoint),query);
}
export async function GetScheduleListReportSelected(query: AuthorityScheduleSearch){
    var endpoint = "ReportsPresentation/ScheduleListSelected";
    return await protectedReportPost((setAPIURL+endpoint),query);
}
export async function LoadAllLists(){
    var endpoint = "api/ListBuilder/LoadAllLists";
    var querystring = "";
    return await protectedGet<Array<ListBuilderMetaData>>((setAPIURL+endpoint+querystring));
//     var array :Array<ListBuilderMetaData>=[] 
//     return  array;
 }

export async function LoadList(listID){
    var endpoint = "api/ListBuilder/LoadList";
    var querystring = '?listID='+listID;
    return await protectedGet<ListBuilderModel>((setAPIURL+endpoint+querystring));
    // var data :ListBuilderModel
    // if(listID==2){
    // data  = {cid:'12',name:'name',uarn:['9011374000','7346232000','1000'],listID:'2'}}
    // else{
    //     data = {cid:'12',name:'name',uarn:['3530724000','424872117'],listID:'5'}

    // }
    // return data;
}

export async function SaveList(listData){
    var endpoint = "api/ListBuilder/SaveList";
    return await protectedPost<string>((setAPIURL+"api/ListBuilder/NewList"),listData);
}

export async function UpdateList(listData){
    var endpoint = "api/ListBuilder/UpdateList";
    return await protectedPost<string>((setAPIURL+"api/ListBuilder/SaveList"),listData);
}

export async function DeleteList(listID){
    return await protectedPost<string>((setAPIURL+"api/ListBuilder/DeleteList?ListID="+listID),{});
}

export async function GetBASchedule(uarn){
    return await protectedGet<Array<BASchedule>>((setAPIURL+"api/Details/GetBASchedule?uarn="+uarn));

}

export async function GetVTS(uarn){
    return await protectedGet<Array<BASchedule>>((setAPIURL+"api/Details/GetVTS?uarn="+uarn));

}

export async function GetDirectory(uarn){
    return await protectedGet<DirectoryBA>((setAPIURL+"api/Details/GetDirectory?uarn="+uarn));

}

export async function GetSummaryValuationInfo(uarn, year,keyID = undefined, archive?){
   var url = "api/Details/GetSurveyInfo?uarn="+uarn+"&list="+year;
   url +=keyID?'&key='+keyID:'';
   url +=archive?'&archive='+archive:'';
    return await protectedGet<SummaryValuation>(setAPIURL+url);   

}

export async function GetSVSurveyPieChart(uarn, year){
    
    return await protectedGet<SVSurveyPieChart>((setAPIURL+"api/Details/GetFloorTypes?uarn="+uarn+"&list="+year)); 
   
}

export async function GetDescriptionByFloor(uarn, year, floor){
    
    return await protectedGet<SVSurveyPieChart>((setAPIURL+"api/Details/GetDescriptionByFloor?uarn="+uarn+"&list="+year +"&floor="+floor)); 
   
}

export async function GetAuthorityScheduleByCase(caseid){
    return await protectedGet<Array<AuthorityScheduleResults>>((setAPIURL+`api/Search/GetAllInCaseID?CaseID=${caseid}&pageno=0&pagesize=1000`))
}

export async function GetSummaryValuationByScheme(scheme,listyear,pageno=0,orderby="Key_field",orderdirection="asc"){
    return await protectedGet<Array<SummaryValuationResults>>((setAPIURL+`api/Search/GetAllInScheme?listYear=${listyear}&schemeID=${scheme}&pageno=${pageno}&pagesize=100&orderby=${orderby}&orderdirection=${orderdirection}`))
}
   
export async function GetAuthorityScheduleExcelSelected(query : string){
    var endpoint = "api/ReportsAPI/ScheduleSelectedExcel/";
    var data = await protectedPost<Array<AuthorityScheduleExcel>>((setAPIURL+endpoint), { keyID : query});
    return strictObjectArray(data,AuthorityScheduleExcel)
}
   
export async function GetAuthorityScheduleExcel(query : AuthorityScheduleSearch){
    var endpoint = "api/Search/GetAuthorityScheduleExcel/";
    var data = await protectedPost<Array<AuthorityScheduleExcel>>((setAPIURL+endpoint),query);
    return strictObjectArray(data,AuthorityScheduleExcel)
}
export async function GetIntegratedListExcel(query : IntegratedSearch){
    var endpoint = "api/Search/GetIntegratedListExcel/";
    var data = await protectedPost<Array<IntegratedListExcel>>((setAPIURL+endpoint),query);
    return strictObjectArray(data,IntegratedListExcel)

}

export async function GetSummaryValuationExcel(query : SummaryValuationSearch){
    var endpoint = "api/Search/GetSummaryValuationExcel/";
    var data = await protectedPost<Array<SummaryValuationResults>>((setAPIURL+endpoint),query);
    return await strictObjectArray(data,SummaryValuationExcel) 

}
export async function GetValuationTribunalExcelSelected(query : string){
    var endpoint = "api/ReportsAPI/VTSRecordExcel/";
    var data = await protectedPost<Array<ValuationTribunalExcel>>((setAPIURL+endpoint), { vtsid : query});
    return strictObjectArray(data,ValuationTribunalExcel)
}

export async function GetValuationTribunalExcel(query : ValuationTribunalSearch){
    var endpoint = "api/Search/GetValuationTribunalExcel/";
    var data = await protectedPost<Array<ValuationTribunalExcel>>((setAPIURL+endpoint),query);
    return strictObjectArray(data,ValuationTribunalExcel)

}
function strictObjectArray(dataArray, cls)  {
    const allowedKeys = Object.getOwnPropertyNames(new cls)

    return dataArray.map(item => {
      return Object.fromEntries(
        Object.entries(item).filter(([key]) => allowedKeys.includes(key)) 
      );
    });
}
export async function postLogThis(record){
    await protectedPost<null>((setAPIURL+"api/Service/External/"), {"objectToLog": record});
}
export async function CreateView(view){
    return await protectedPost<number>((setAPIURL+"api/Service/CreateView/"), view); 
}
export async function SaveView(view){
    await protectedPost<void>((setAPIURL+"api/Service/SaveView/"), view);
}
export async function GetAllViews(){
    return await protectedPost<Array<UserViewSettings>>((setAPIURL+"api/Service/LoadAllViews/"), {});
}
export async function DeleteView(view){
    return await protectedPost<Array<UserViewSettings>>((setAPIURL+"api/Service/DeleteView/"), view);
}
export async function GetSentToRiverlakeMonitor(request){
    return await protectedPost<Partial<SendToRiverlakeDetails>>((setAPIURL+"api/RLPump/GetSentToRiverlakeMonitor/"), request);
}
export async function GetSendToRiverlake(){
    var results = await protectedPost<Array<SendToRiverlake>>(setAPIURL+'api/RLPump/GetSendToRiverlake', {})
    results = results? results: []
    return results.sort(((a, b) =>  b.rlSendToRiverlakeID! - a.rlSendToRiverlakeID!))
}
export async function SendToRiverlakeFunc(sendToRiverlake){
    return await protectedPost<SendToRiverlake>(setAPIURL+'api/RLPump/SendToRiverlake', sendToRiverlake)
}
export async function UpdateSendToRiverlake(sendToRiverlake){
    return await protectedPost<SendToRiverlake>(setAPIURL+'api/RLPump/SendToRiverLakeUpdate', sendToRiverlake)
}
export async function DeleteSendToRiverlake(id){
    return await protectedPost<SendToRiverlake>(setAPIURL+'api/RLPump/SendToRiverLakeDelete', {rlSendToRiverlakeID:id})
}
export async function CreateExportSession(exportSession){
    return await protectedPost<number>(setAPIURL+'api/RLPump/CreateExportSession', exportSession)
}
export async function StartExportSession(exportSession){
    return await protectedPost<ExportSession>(setAPIURL+'api/RLPump/StartExportSession', exportSession)
}
export async function GetSingleSendToRiverlakeInternal(sendToRiverlake){
    return await protectedPost<SendToRiverlake>(setAPIURL+'api/RLPump/GetSingleSendToRiverlakeInternal', sendToRiverlake)
}
export async function CreateItemToSend(sendToRiverlake){
    return await protectedPost<ExportSession>(setAPIURL+'api/RLPump/CreateItemToSend', sendToRiverlake)
}
export async function GetRLClients() {
    var results = await protectedPost<RLClient[]>(setAPIURL+'api/RLPump/GetListOfClients', {})
    results = results? results: []
    return results.sort((a,b)=>a.clientName!.trim().localeCompare(b.clientName!.trim()))
}
export async function GetUserList() {
    return await protectedPost<UserAdmin[]>(setAPIURL+'api/Authenticate/ListUsers', {})
}
export async function DeleteUser(userid) {
    return await protectedPost(setAPIURL+'api/Authenticate/DeleteUser?UserID='+userid, {})
}
export async function NewSecretKey() {
    return await protectedPost<string>(setAPIURL+'api/Authenticate/NewSecretKey', {})
}