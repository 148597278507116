import React, { useState, useEffect, useRef, } from 'react';
import { GridColDef, GridRowSelectionModel, GridColumnGroup, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../API/AnalyseAPIHelper';

import store from '../Redux/ConfigureStore';
import { formatDateToString, FormatNumberWithCommas, FormatSettlementCode, stringifyList } from '../Services/FormatService';
import FlagComponent from './SubComponents/FlagComponent';

import { useSelector } from 'react-redux';
import { setDisplayRiverlake, selectListLength, selectListList, selectPrunedUARNs, selectShowRiverlakeDetails, selectSummaryListYear, selectTriggerDeleteSelection, selectDisplayRiverlake, setRiverlakeUARNs, resetDuplicateRiverlakeUARN, selectHeaderBarContext, setHeaderBarContext, selectRLSendToRiverlakeID, setTriggerRiverlakeDeleteSelection } from '../Redux/RiverlakeSlicer';
import CloseIcon from '@mui/icons-material/Close';
import RiverlakeBarComponent from './HeaderBarComponents/RiverlakeBarComponent';
import {RiverlakeTable, RiverlakeTableRef} from './TableComponents/RiverlakeTable';
import HomeIcon from '@mui/icons-material/Home';
import IntegratedSearch from '../Models/SearchApiModels/IntegratedSearch';
import BasicSearch from '../Models/BasicSearch';
import BasicSearchApiModel from '../Models/SearchApiModels/BasicSearchApiModel';
import { getConfig, GetScheme } from '../API/AnalyseAPIHelper';
import { Button, ClickAwayListener } from '@mui/material';
import { setLoading } from '../Redux/SearchSlicer';
import IntegratedListResults from '../Models/IntegratedListResult';
import { selectIntListDbString, selectViewID, setRiverlakeSettings, setViewId } from '../Redux/UserSlicer';
import RiverlakeSessionsComponent from './RiverlakeSessionsComponent';





export default function RiverlakeComponent(props) {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const showRiverlake = useSelector(selectDisplayRiverlake);
  const uarnList = useSelector(selectListList) as Array<string>;
  const [resultsArray, setResultsArray] = useState<Array<IntegratedListResults>>([]);
  const showRiverlakeDetails = useSelector(selectShowRiverlakeDetails);
  const [selectedUARN, setSelectedUARN] = useState<string>();
  const [tableState, setTableState] = useState()
  const [closeRiverlake, setCloseRiverlake] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const RiverlakeCount = useSelector(selectListLength);
  const headerBarContext = useSelector(selectHeaderBarContext);
  const [report,setReport] = useState();
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const duplicateUARNs = useSelector(selectPrunedUARNs);
  const deleteRows = useSelector(selectTriggerDeleteSelection);
  const intListDbString = useSelector(selectIntListDbString);
  const [intListVisibility,setIntListVisibility] = useState(store.getState().user.intListVisibility);
  const tableRef = useRef<RiverlakeTableRef>(null);
  const viewId = useSelector(selectViewID);
      const rlSendToRiverlakeID = useSelector(selectRLSendToRiverlakeID);

  const initialColumns: GridColDef[] = [
    {
      field: 'flaG_2023',
      headerName: '23',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_2023 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2023 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2023 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2017',
      headerName: '17',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_2017 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2017 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2010',
      headerName: '10',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_2010 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2010 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2005',
      headerName: '05',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_2005 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2005 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2000',
      headerName: '00',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_2000 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2000 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2000 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1995',
      headerName: '95',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_1995 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1995 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1990',
      headerName: '90',
      width: 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div >
          {(params.row.rateablE_VALUE_1990 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1990 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1990 == null && !params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1990 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'firm_name', headerName: 'FIRM NAME', width: 78, minWidth: 78, headerAlign: 'left',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'address', headerName: 'ADDRESS', width: 458, headerAlign: 'left',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'postcode', headerName: 'POSTCODE', width: 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE', width: 104, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'assessmenT_REFERENCE_2023', headerName: 'ASSESSMENT REF (LIVE 2023)', width: 88, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined && Number(params.value) != 0 && params.value != '0' ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'description', headerName: 'DESCRIPTION', width: 175, headerAlign: 'left',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'psD_CODE', headerName: 'PSD', width: 34, minWidth: 34, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'scat', headerName: 'SCAT', width: 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div >
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2023', headerName: 'RATEABLE VALUE 2023', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
        
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2023 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2023', headerName: 'EFFECTIVE DATE 2023', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023 == null && row.flaG_2023 == null)  {return 'IN 2023';}
        return formatDateToString(value)
      },
    },
    {
      field: 'lisT_ALTERATION_DATE_2023', headerName: 'LIST ALTERATION DATE 2023', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023  == null && row.flaG_2023 == null)  {return 'LIST';}
        return formatDateToString(value)
      },
    },
    {
      field: 'compositE_2023', headerName: 'COMPOSITE 2023', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}

        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2023', headerName: 'SETTLEMENT TYPE 2023', width: 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2023, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(row.lisT_YEAR,value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2023', headerName: 'COMPILED RATEABLE VALUE 2023', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2023', headerName: 'LIST PERCENTAGE 2023', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2023_2017', headerName: 'PERCENTAGE BETWEEN 2023 AND 2017', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2023_RATEABLE_VALUE', headerName: 'DRAFT 2023 RATEABLE VALUE', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2023 == null ? 'emptycell right' : 'bluecell'} >
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2017', headerName: 'RATEABLE VALUE 2017', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2017 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2017', headerName: 'EFFECTIVE DATE 2017', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2017 == null ? 'emptycell' : 'bluecell'} >
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'IN 2017';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2017', headerName: 'LIST ALTERATION DATE 2017', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2017', headerName: 'COMPOSITE 2017', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      )
    },
    {
      field: 'settlemenT_TYPE_2017', headerName: 'SETTLEMENT TYPE 2017', width: 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2017, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2017, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2017', headerName: 'COMPILED RATEABLE VALUE 2017', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2017', headerName: 'LIST PERCENTAGE 2017', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2017_2010', headerName: 'PERCENTAGE BETWEEN 2017 AND 2010', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2017_RATEABLE_VALUE', headerName: 'DRAFT 2017 RATEABLE VALUE', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2017 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2010', headerName: 'RATEABLE VALUE 2010', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2010 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2010', headerName: 'EFFECTIVE DATE 2010', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'IN 2010';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2010', headerName: 'LIST ALTERATION DATE 2010', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2010', headerName: 'COMPOSITE 2010', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2010', headerName: 'SETTLEMENT TYPE 2010', width: 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2010, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2010, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2010', headerName: 'COMPILED RATEABLE VALUE 2010', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? FormatNumberWithCommas(params.value) : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2010', headerName: 'LIST PERCENTAGE 2010', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2010_2005', headerName: 'PERCENTAGE BETWEEN 2010 AND 2005', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2010_RATEABLE_VALUE', headerName: 'DRAFT 2010 RATEABLE VALUE', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2010 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2005', headerName: 'RATEABLE VALUE 2005', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2005 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2005', headerName: 'EFFECTIVE DATE 2005', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'IN 2005';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2005', headerName: 'LIST ALTERATION DATE 2005', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2005', headerName: 'COMPOSITE 2005', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2005', headerName: 'SETTLEMENT TYPE 2005', width: 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2005, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2005, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2005', headerName: 'COMPILED RATEABLE VALUE 2005', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2005', headerName: 'LIST PERCENTAGE 2005', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2005_2000', headerName: 'PERCENTAGE BETWEEN 2005 AND 2000', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2005 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2000', headerName: 'RATEABLE VALUE 2000', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2000 != null)  {return 'DELETED';}
        return '\u200B';
      }
    },
    {
      field: 'effectivE_DATE_2000', headerName: 'EFFECTIVE DATE 2000', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2000', headerName: 'LIST ALTERATION DATE 2000', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_2000', headerName: 'COMPOSITE 2000', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2000', headerName: 'SETTLEMENT TYPE 2000', width: 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2000, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2000, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2000', headerName: 'COMPILED RATEABLE VALUE 2000', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2000', headerName: 'LIST PERCENTAGE 2000', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2000_1995', headerName: 'PERCENTAGE BETWEEN 2000 AND 1995', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_2000 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1995', headerName: 'RATEABLE VALUE 1995', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1995 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1995', headerName: 'EFFECTIVE DATE 1995', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'IN 1995';}
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1995', headerName: 'LIST ALTERATION DATE 1995', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'LIST';}
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_1995', headerName: 'COMPOSITE 1995', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1995', headerName: 'SETTLEMENT TYPE 1995', width: 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1995, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1995, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1995', headerName: 'COMPILED RATEABLE VALUE 1995', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1995', headerName: 'LIST PERCENTAGE 1995', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_1995_1990', headerName: 'PERCENTAGE BETWEEN 1995 AND 1990', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1995 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1990', headerName: 'RATEABLE VALUE 1990', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1990 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1990', headerName: 'EFFECTIVE DATE 1990', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 == null && row.flaG_1990 == null)  {return 'IN 1990';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1990', headerName: 'LIST ALTERATION DATE 1990', width: 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990== null && row.flaG_1990 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_1990', headerName: 'COMPOSITE 1990', width: 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1990', headerName: 'SETTLEMENT TYPE 1990', width: 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1990, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1990, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1990', headerName: 'COMPILED RATEABLE VALUE 1990', width: 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1990', headerName: 'LIST PERCENTAGE 1990', width: 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div  className={params.row.flaG_1990 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    }
  ];
  const [columns, setColumns] = useState(initialColumns);



const handleColumnResize = async(params) => {
  const { colDef, width } = params;
  await setColumns((prevColumns) =>
    prevColumns.map((col) =>
      col.field === colDef.field ? { ...col, width } : col
    )
  );
  if(tableRef.current){
    tableRef.current.handleThisExportModel()
  }
};

const handleVisibilityChange = async(params) =>{
    
  await setIntListVisibility(params)
  if(tableRef.current){
    tableRef.current.handleThisExportModel()
  }
}
  
  const handleDeleteFromRiverlake = async () => {
    store.dispatch(setLoading(true))  
    const newListList = await uarnList.filter((item) => !uarnSelectionModel.includes(item)); 
    store.dispatch(setRiverlakeUARNs(newListList));
    store.dispatch(setLoading(false))    
  }

  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
    window.open(url, '_blank', 'noopener,noreferrer');
    }
  };
  useEffect(()=>{
    console.log(store.getState())
    if (store.getState().riverlake.listList.length>0){
      getPreloadedList()
    }
  },[])
  const getPreloadedList=async()=>{
      let config = await getConfig();
      handleGetListData();
  }  
  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return resultsArray.find(row => row.keY_FIELD === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);





  useEffect(() => {
    if(uarnList.length != 0){
      handleGetListData();
    }else{
      setResultsArray([])
      setSelectedUARN(undefined)
      setRowSelectionModel([])
    }
  }, [uarnList])



  const handleRowClick = (e) => {
    const { row } = e;
    if (row.uarn !== selectedUARN) {
      setSelectedUARN(row.uarn);

    }
  }


  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };
  useEffect(()=>{
    if(deleteRows == true){
      handleDeleteFromRiverlake();
      store.dispatch(setTriggerRiverlakeDeleteSelection(false))

    }
  },[deleteRows])
  const handleMenuItemClick = (item) => {
    switch (item) {
      case "Delete Assessment(s)":
        handleDeleteFromRiverlake();
        break;

      default:
        break;
    }
    handleCloseContextMenu(null);
  };

  const handleGetListData = async () => {
    try {
      var query = new IntegratedSearch();
      var basic = new BasicSearch()
      basic.uarn = stringifyList(uarnList)
      query.basic = new BasicSearchApiModel(basic);
      query.report = true
      var data = await ApiHelper.GetIntegratedList(query);
      data = data?data:[];
      setResultsArray(data);
      if(data.length>0){
        setSelectedUARN(data[0].uarn)
        setRowSelectionModel([data[0].keY_FIELD!])
      }
    }
    catch (ex) {
      console.log(ex)

    }
  }
  const handleSetTableState = (state) => {
    setTableState(state);
    setCloseRiverlake(false);
  }

  const handleClose = () => {
    store.dispatch(setDisplayRiverlake(false));
  }
  useEffect(() => {
    if (tableState != null) { store.dispatch(setDisplayRiverlake(false)); }
  }, [tableState])




  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'STATUS/ADDRESS',
      children: [{ field: 'flaG_1990' }, { field: 'flaG_1995' }, { field: 'flaG_2000' }, { field: 'flaG_2005' }, { field: 'flaG_2010' }, { field: 'flaG_2017' }, { field: 'flaG_2023' }, { field: 'firm_name' }, { field: 'address' }, { field: 'postcode' }],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'assessmenT_REFERENCE_2023' }, { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1990 LIST',
      children: [{ field: 'rateablE_VALUE_1990' }, { field: 'effectivE_DATE_1990' }, { field: 'lisT_ALTERATION_DATE_1990' }, { field: 'compositE_1990' }, { field: 'settlemenT_TYPE_1990' }, { field: 'compileD_RATEABLE_VALUE_1990' }, { field: 'lisT_PERCENTAGE_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1995 LIST',
      children: [{ field: 'rateablE_VALUE_1995' }, { field: 'effectivE_DATE_1995' }, { field: 'lisT_ALTERATION_DATE_1995' }, { field: 'compositE_1995' }, { field: 'settlemenT_TYPE_1995' }, { field: 'compileD_RATEABLE_VALUE_1995' }, { field: 'lisT_PERCENTAGE_1995' },{ field: 'percentagE_BETWEEN_1995_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2000 LIST',
      children: [{ field: 'rateablE_VALUE_2000' }, { field: 'effectivE_DATE_2000' }, { field: 'lisT_ALTERATION_DATE_2000' }, { field: 'compositE_2000' }, { field: 'settlemenT_TYPE_2000' }, { field: 'compileD_RATEABLE_VALUE_2000' }, { field: 'lisT_PERCENTAGE_2000' },{ field: 'percentagE_BETWEEN_2000_1995' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2005 LIST',
      children: [{ field: 'rateablE_VALUE_2005' }, { field: 'effectivE_DATE_2005' }, { field: 'lisT_ALTERATION_DATE_2005' }, { field: 'compositE_2005' }, { field: 'settlemenT_TYPE_2005' }, { field: 'compileD_RATEABLE_VALUE_2005' }, { field: 'lisT_PERCENTAGE_2005' },{ field: 'percentagE_BETWEEN_2005_2000' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2010 LIST',
      children: [{ field: 'rateablE_VALUE_2010' }, { field: 'effectivE_DATE_2010' }, { field: 'lisT_ALTERATION_DATE_2010' }, { field: 'compositE_2010' }, { field: 'settlemenT_TYPE_2010' }, { field: 'compileD_RATEABLE_VALUE_2010' }, { field: 'lisT_PERCENTAGE_2010' },{ field: 'percentagE_BETWEEN_2010_2005' }, { field: 'drafT_2010_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2017 LIST',
      children: [{ field: 'rateablE_VALUE_2017' }, { field: 'effectivE_DATE_2017' }, { field: 'lisT_ALTERATION_DATE_2017' }, { field: 'compositE_2017' }, { field: 'settlemenT_TYPE_2017' }, { field: 'compileD_RATEABLE_VALUE_2017' }, { field: 'lisT_PERCENTAGE_2017' }, { field: 'percentagE_BETWEEN_2017_2010' }, { field: 'drafT_2017_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }, {
      groupId: '2023 LIST',
      children: [{ field: 'rateablE_VALUE_2023' }, { field: 'effectivE_DATE_2023' }, { field: 'lisT_ALTERATION_DATE_2023' }, { field: 'compositE_2023' }, { field: 'settlemenT_TYPE_2023' }, { field: 'compileD_RATEABLE_VALUE_2023' }, { field: 'lisT_PERCENTAGE_2023' }, { field: 'percentagE_BETWEEN_2023_2017' }, { field: 'drafT_2023_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]
  const rows = resultsArray ? resultsArray : [];    

  useEffect(() => {
      setPersonalisedView()
  },[viewId])

  useEffect(() => {
      setPersonalisedView()
  },[intListDbString])

  const setPersonalisedView = ()=>{
    const {intListOrder,intListDimensions,intListVisibility} = store.getState().user
  
    var ordered : GridColDef[] =[];
    var order = intListOrder
    if(order.length > 0){
      for(var i =0; i< order.length; i++){
        var result = columns.find(ele=>ele.field == order[i])
  
        if(result){
          result.width = intListDimensions[order[i]].width;
  
          ordered.push(result)
  
        }
      };
      console.log(tableState)
      setIntListVisibility(intListVisibility)
      setColumns(ordered)
    }
  }
  const handleBarClick=(headerbar)=>{
    store.dispatch(setHeaderBarContext(headerbar))
  }


  return (
    <>
      {showRiverlake && <>
        <div className='popoverbackground'></div>
        <div className='popover'>
          <div className='popovercontainer'>
            <div className="layoutcolumn">
              <div className="headercontextbar layoutrow">
                  <div className={headerBarContext == 'riverlake' ? "headercontextoption selected" : "headercontextoption"}  onClick={() => (handleBarClick('riverlake'))}>
                    Riverlake
                  </div>
                  <div className={headerBarContext == 'sessions' ? "headercontextoption selected" : "headercontextoption"} onClick={() => (handleBarClick('sessions'))}>
                    Sessions
                  </div>
                  {headerBarContext == 'riverlake' && rlSendToRiverlakeID >0&&<div className='sendToriverlakelabelheader'>{store.getState().riverlake.label}</div>}
              </div>
              <div className='closeme Riverlake'  onClick={handleClose}>
                <CloseIcon sx={{color:'white'}}/>
              </div>
              {headerBarContext == 'riverlake' &&
              <>
                <div className='mainheaderbar'>

                   <RiverlakeBarComponent />

                </div>
                <div className='popovergridcontainer'>
                  <div className={showRiverlakeDetails ? "gridcontainer details Riverlake" : "gridcontainer Riverlake"} >
                    <RiverlakeTable ref={tableRef} handleVisibilityChange={handleVisibilityChange}  intListVisibility={intListVisibility}  columnGroupingModel={columnGroupingModel} columns={columns} rows={rows} rowSelectionModel={rowSelectionModel} setRowSelectionModel={setRowSelectionModel} handleRowClick={handleRowClick} setTableState={handleSetTableState} tableState={tableState}
                     setContextMenuPosition={setContextMenuPosition} triggerSaveTableState={closeRiverlake} contextMenuPosition={contextMenuPosition} handleCloseContextMenu={handleCloseContextMenu} handleMenuItemClick={handleMenuItemClick} handleColumnWidthChange={handleColumnResize}/>
                  </div>
                </div>
              </>
              }
              {headerBarContext == 'sessions' &&
              <>
                <RiverlakeSessionsComponent />
              </>
              }
            </div>
          </div>
        </div>
          
      </>
      }
          {duplicateUARNs> 0 &&
            <ClickAwayListener onClickAway={(event) =>(store.dispatch(resetDuplicateRiverlakeUARN()))}>
            <div className='noschemepopup'>
            <div className='closeme' onClick={()=>store.dispatch(resetDuplicateRiverlakeUARN())}>
              <CloseIcon />
            </div>
                <div className='popupheader'>Analyse</div>
                <div className='popupbody'>{duplicateUARNs} duplicate rows were not added.</div>
                <Button variant='outlined' className='popupbutton' onClick={()=>store.dispatch(resetDuplicateRiverlakeUARN())}>OK</Button>
            </div>
          </ClickAwayListener>
          }
    </>
  );


}