import { createSlice } from "@reduxjs/toolkit";
import BasicSearch from "../Models/BasicSearch";
import IntegratedSearch from "../Models/SearchApiModels/IntegratedSearch";
import SummaryValuationSearch from "../Models/SearchApiModels/SummaryValuationSearch";
import ValuationTribunalSearch from "../Models/SearchApiModels/ValuationTribunalSearch";
import AuthorityScheduleSearch from "../Models/SearchApiModels/AuthorityScheduleSearch";
import IntegratedListTrends from "../Models/IntegratedListTrends";
import { SurveyLinesSurvey } from "../Models/SurveyLinesSurvey";
import { SurveyAdvanced } from "../Models/SurveyAdvanced";
import AuthorityScheduleAdv1 from "../Models/AuthorityScheduleAdv1";
import AuthorityScheduleAdv2 from "../Models/AuthorityScheduleAdv2";
import IntegratedListAdvancedSearch from "../Models/IntegratedListAdvancedSearch";
import BasicCodeSearch from "../Models/BasicCodeSearch";
import AreaSearch from "../Models/AreaSearch";
import IntegratedSettlement from "../Models/IntegratedSettlement";
import { ValuationTribunalVTS } from "../Models/ValuationTribunalVTS";

const initialState = {
  tableContext:0, //based on enum
  basicSearch: new BasicSearch().serialize(),
  integratedListTrends: new IntegratedListTrends().serialize(),
  surveyLinesSurveySearch: new SurveyLinesSurvey().serialize(),
  advancedSurveySearch: new SurveyAdvanced().serialize(),
  authorityScheduleAdv1: new AuthorityScheduleAdv1().serialize(),
  authorityScheduleAdv2: new AuthorityScheduleAdv2().serialize(),
  integratedListAdvancedSearch: new IntegratedListAdvancedSearch().serialize(),
  basicCodeSearch: new BasicCodeSearch().serialize(),
  integratedSettlementSearch: new IntegratedSettlement().serialize(),
  areaSearch: new AreaSearch().serialize(),
  valuationTribunalVTS: new ValuationTribunalVTS().serialize(),
  pageno: 0,
  sortcolumn:  "l.key_field",
  sortorder: "asc",
  summaryListYear: 2023,
  summaryArchive: 0,
  //sqlDump:false,
  svIsLive:true,
  total:0,
  count:0,
  triggerGetTotal:0,
  triggerGetCount:0,
  triggerGOVUK:false,
  triggerGoogleMaps:false,
  headerBarContext:0,
  selectedUARN:'',
  displayDetails:false,
  triggerPrint:[0,0],
  loading : false,
  triggerShowScheme:false,
  triggerExportExcel:0,
  DARLiveHighlight:false,
  pinColumns:false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setTableContext(state,action){
      state.tableContext = action.payload;
      state.total = 0;
      state.count = 0;
    },
    setBasicSearch(state, action) {
      state.basicSearch = action.payload;
    },
    setTrendSearch(state, action) {
      state.integratedListTrends = action.payload;
    },
    setSummaryListYear(state,action){
      state.summaryListYear = action.payload;
    },
    setSummaryArchive(state,action){
      state.summaryArchive = action.payload;
    },
    setSurveyLinesSurveySearch(state,action){
      state.surveyLinesSurveySearch = action.payload;
    },
    setAdvancedSurveySearch(state,action){
      state.advancedSurveySearch = action.payload;
    },
    setAuthorityScheduleAdv1Search(state,action){
      state.authorityScheduleAdv1 = action.payload;
    },
    setAuthorityScheduleAdv2Search(state,action){
      state.authorityScheduleAdv2 = action.payload;
    },
    setIntegratedListAdvancedSearch(state,action){
      state.integratedListAdvancedSearch = action.payload;
    },
    // setSQLDumpSearch(state,action){
    //   state.sqlDump = action.payload;
    // },
    setBasicCodeSearch(state,action){
      state.basicCodeSearch = action.payload;

    },
    setAreaSearch(state,action){
      state.areaSearch = action.payload;

    },
    setIntegratedSettlementSearch(state,action){
      state.integratedSettlementSearch = action.payload;

    },
    setValuationTribunalVTSSearch(state,action){
      state.valuationTribunalVTS = action.payload;

    },
    setSVIsLive(state,action){
      state.svIsLive = action.payload;

    },
    clearSearch(state,action){
      state.basicSearch = new BasicSearch().serialize();
      state.integratedListTrends = new IntegratedListTrends().serialize();
      state.surveyLinesSurveySearch = new SurveyLinesSurvey().serialize();
      state.advancedSurveySearch = new SurveyAdvanced().serialize();
      state.authorityScheduleAdv1 = new AuthorityScheduleAdv1().serialize();
      state.authorityScheduleAdv2 = new AuthorityScheduleAdv2().serialize();
      state.integratedListAdvancedSearch = new IntegratedListAdvancedSearch().serialize();
      state.basicCodeSearch= new BasicCodeSearch().serialize();
      state.areaSearch = new AreaSearch().serialize();
      state.integratedSettlementSearch = new IntegratedSettlement().serialize();
      state.valuationTribunalVTS = new ValuationTribunalVTS().serialize();
      state.svIsLive = true;
      state.selectedUARN = '';
    },
    setTotal(state,action){
      state.total = action.payload
    },
    setCount(state,action){
      state.count = action.payload
    },
    setTriggerGetCount(state,action){
      state.triggerGetCount = action.payload
    },
    setTriggerGetTotal(state,action){
      state.triggerGetTotal = action.payload
    },
    setTriggerGoogleMaps(state,action){
      state.triggerGoogleMaps = action.payload
    },
    setTriggerGOVUK(state,action){
      state.triggerGOVUK = action.payload
    },
    setHeaderBarContext(state,action){
      state.headerBarContext = action.payload
    },
    setSelectedUARN(state,action){
      state.selectedUARN = action.payload
    },
    setDisplayDetails(state,action){
      state.displayDetails = action.payload
    },
    setTriggerPrint(state,action){
      state.triggerPrint = action.payload
    },
    setLoading(state,action){
      state.loading = action.payload
    },
    setTriggerShowScheme(state,action){
      state.triggerShowScheme = action.payload
    },
    setTriggerExportExcel(state,action){
      state.triggerExportExcel = action.payload
    },
    setDARLiveHighlight(state,action){
      state.DARLiveHighlight = action.payload
    },
    setPinColumns(state,action){
      state.pinColumns = action.payload
    },
    resetSearch(state){
      return initialState;
    }
  },
});
export const { setPinColumns,setDARLiveHighlight,resetSearch,setTriggerExportExcel,setLoading, setBasicSearch,setTriggerPrint,setSelectedUARN,setDisplayDetails,setHeaderBarContext,setTriggerGOVUK,setTriggerGoogleMaps,setTriggerGetTotal,setTotal,setTriggerGetCount,setCount,setSummaryListYear,setTableContext, setAuthorityScheduleAdv1Search, setAuthorityScheduleAdv2Search
  ,setSummaryArchive,setTriggerShowScheme,setTrendSearch, clearSearch,setSurveyLinesSurveySearch,setAdvancedSurveySearch, setIntegratedListAdvancedSearch
  ,setBasicCodeSearch,setAreaSearch,setIntegratedSettlementSearch,setValuationTribunalVTSSearch,setSVIsLive } = searchSlice.actions;

export default searchSlice.reducer;

export const selectListYear = state => state.search.summaryListYear;


export const selectTableContext =state => state.search.tableContext;

export const selectSummaryArchive = state => state.search.summaryArchive;

export const selectSVIsLive = state => state.search.svIsLive;

export const selectTriggerGetTotal = state => state.search.triggerGetTotal;

export const selectTriggerGetCount = state => state.search.triggerGetCount;

export const selectCount = state => state.search.count;

export const selectTotal = state => state.search.total;

export const selectTriggerGoogleMaps = state => state.search.triggerGoogleMaps;

export const selectTriggerGOVUK = state => state.search.triggerGOVUK;


export const selectHeaderBarContext = state => state.search.headerBarContext;


export const selectSelectedUARN = state => state.search.selectedUARN;

export const selectDisplayDetails = state => state.search.displayDetails;

export const selectTriggerPrint = state => state.search.triggerPrint;

export const SelectIsLoading = state => state.search.loading;

export const selectTriggerShowScheme = state=>state.search.triggerShowScheme;

export const selectTriggerExportExcel = state=>state.search.triggerExportExcel;

export const selectIntegratedListSettlement = state=>state.search.integratedSettlementSearch;

export const selectDARLiveHighlight = state=>state.search.DARLiveHighlight;

export const selectPinColumns = state => state.search.pinColumns;
