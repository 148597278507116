import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface RiverlakeState {
    listList: string[];
    savedList:string[];
    dispatchAddSelection:boolean;
    displayRiverlake:boolean;
    resetRiverlakeTableSort:boolean;
    headerBarContext:string;
    rlSendToRiverlakeID:number,
    cid:string;
    changesMade:boolean,
    reloadList:boolean,
    summaryListYear:number,
    label:string;
    prunedUARNs:number,
    triggerDeleteSelection:boolean,
    pinColumns:boolean,
}
const initialState:RiverlakeState = {
    listList :[],
    dispatchAddSelection:false,
    displayRiverlake:false,
    resetRiverlakeTableSort:false,
    rlSendToRiverlakeID:0,
    changesMade:false,
    reloadList:false,
    summaryListYear:2023,
    cid:'',
    label:'',
    prunedUARNs:0,
    savedList:[],
    triggerDeleteSelection:false,
    pinColumns:false,
    headerBarContext:'riverlake',
}
const riverlakeSlice = createSlice({
    name: 'riverlake',
    initialState,
    reducers: {
        addRiverlakeUARNs(state, action) {
            var tempArray = [...state.listList,...action.payload]
            var tempLength = tempArray.length;
            var uniqueSet = new Set(tempArray);
            const uniqueArr = Array.from(uniqueSet);
            const numberOfDuplicatesRemoved = tempLength - uniqueArr.length; 
            return{
                ...state,
                listList:uniqueArr,
                prunedUARNs:numberOfDuplicatesRemoved,
                changesMade:true,
            }
        },
        setRiverlakeUARNs(state,action){    
            var unsaved = true    
            if(action.payload.length ==0 && state.rlSendToRiverlakeID == 0){
                unsaved = false
            }
              return {
                ...state,
                listList: [...action.payload],
                changesMade:unsaved,
              };
            
        },
        setDispatchAddRiverlakeSelection(state,action){
            state.dispatchAddSelection = action.payload
        },
        setDisplayRiverlake(state,action){
            state.displayRiverlake = action.payload
        },
        emptyRiverlakeArray(state){
            return {
                ...state,
                listList: [],
                savedList: [],
                rlSendToRiverlakeID: 0,
                cid:'',
                changesMade:false,
                reloadList:true,
                sharable: 0,
                showRiverlakeDetails:false,
                label:'',
            };
        },
        resetRiverlakeTableSort(state,action){
            state.resetRiverlakeTableSort =  action.payload;
        },
        setRiverlakeListFromSession(state,action){
            var session = action.payload
            var uarns =session.uarn.split(',')
            return{
                ...state,
                listList: uarns,
                rlSendToRiverlakeID: session.rlSendToRiverlakeID,
                changesMade:false,
                headerBarContext:'riverlake',
                label:session.label,
                savedList:uarns,
            };
        },
        setRiverlakeReloadList(state,action){
            state.reloadList = action.payload

        },
        setRiverlakeChangesMade(state,action){
            state.changesMade = action.payload

        },     
        resetDuplicateRiverlakeUARN(state){
            state.prunedUARNs = 0;
        },
        resetRiverlake(state){
            return initialState
            
        },
        setRiverlakePinColumns(state,action){
          state.pinColumns = action.payload
        },
        setTriggerRiverlakeDeleteSelection(state,action){
            state.triggerDeleteSelection = action.payload;
        },
        setHeaderBarContext(state,action){
            state.headerBarContext = action.payload;
        }
    }
});

export const {setRiverlakePinColumns,setHeaderBarContext,resetDuplicateRiverlakeUARN,setTriggerRiverlakeDeleteSelection,resetRiverlake, setRiverlakeChangesMade,setRiverlakeReloadList, setRiverlakeListFromSession, addRiverlakeUARNs, setRiverlakeUARNs,setDispatchAddRiverlakeSelection,setDisplayRiverlake,emptyRiverlakeArray,resetRiverlakeTableSort} = riverlakeSlice.actions;
export default riverlakeSlice.reducer;
export const selectListLength = state => state.riverlake.listList.length;
export const selectAddSelection = state => state.riverlake.dispatchAddSelection;
export const selectDisplayRiverlake = state => state.riverlake.displayRiverlake;
export const selectListList = state => state.riverlake.listList;
export const selectResetRiverlakeTableSort = state => state.riverlake.resetRiverlakeTableSort;
export const selectShowRiverlakeDetails = state => state.riverlake.showRiverlakeDetails;
export const selectRLHasUnsavedChanges = state=>state.riverlake.changesMade;
export const selectSummaryListYear = state =>state.riverlake.summaryListYear;
export const selectPrunedUARNs= state=>state.riverlake.prunedUARNs;
export const selectTriggerDeleteSelection=state => state.riverlake.triggerDeleteSelection;
export const selectPinColumns = state => state.riverlake.pinColumns;
export const selectHeaderBarContext = state =>state.riverlake.headerBarContext;
export const selectRLSendToRiverlakeID  = state =>state.riverlake.rlSendToRiverlakeID;
