import { useSelector } from "react-redux"
import { resetToDefault, selectAuthSchDbString, selectMasterIntListDbString, selectSumValDbString, selectViewArray, selectViewID, selectVirtTribDbString, setSettingsFromDBObject, setViewArray, setViewId } from "../../Redux/UserSlicer"
import { useEffect, useState } from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import store from "../../Redux/ConfigureStore";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, ClickAwayListener } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { setLoading } from "../../Redux/SearchSlicer";
import { UserViewSettings } from "../../Models/UserViewSettings";
import { setCurrentView } from "../../Services/CookieService";
import { ClassNames } from "@emotion/react";

export default function PersonalisedViewsComponent(){
    const viewID = useSelector(selectViewID)
    const viewArray = useSelector(selectViewArray)
    const [showDropdown,setShowDropdown] = useState(false);
    const [viewName,setViewName] = useState('Default');
    const [showNewViewPopup,setShowNewViewPopup] =  useState(false);
    const [template,setTemplate] =  useState(-1);
    const [templateNameChange,setTemplateNameChange] = useState('')
    const [showWarning,setShowWarning] =  useState(false);
    const sumValDbString = useSelector(selectSumValDbString);
    const authSchDbString = useSelector(selectAuthSchDbString);
    const virtTribDbString = useSelector(selectVirtTribDbString);
    const masterIntListDbString = useSelector(selectMasterIntListDbString);
    const [elemToDelete,setElemToDelete] = useState<UserViewSettings>()

    const exportColumnsToApi = async()=>{
        const {viewId,viewName} = store.getState().user;
        var view = new UserViewSettings();
        view.listView= masterIntListDbString;
        view.smvView= sumValDbString;
        view.scheduleView= authSchDbString;
        view.vtsView= virtTribDbString;
        view.viewId = viewId;
        view.viewName = viewName
        store.dispatch(setLoading(true));
        await ApiHelper.SaveView(view);
        var settingData = await ApiHelper.GetAllViews();
        if(settingData && settingData.length > 0){
          store.dispatch(setViewArray(settingData));
        }
        else{
            store.dispatch(setViewArray([]));
              
        }

        store.dispatch(setLoading(false));
    }
    useEffect(()=>{
        const triggerExport = store.getState().user.triggerExport
        if(viewName !='Default' && triggerExport){
            exportColumnsToApi()
        }
        
    },[sumValDbString,authSchDbString,virtTribDbString,masterIntListDbString])

    const handleDeleteView = async()=>{
        store.dispatch(setLoading(true));
        await ApiHelper.DeleteView(elemToDelete);
        if(viewID == elemToDelete!.viewId){
            store.dispatch(resetToDefault())
        }
        var settingData = await ApiHelper.GetAllViews();
        if(settingData && settingData.length > 0){
          store.dispatch(setViewArray(settingData));
        }
        else{
            store.dispatch(setViewArray([]));
              
        }
        var settingData = await ApiHelper.GetAllViews();
        setElemToDelete(undefined)
        store.dispatch(setLoading(false));
    }

    const handleTemplateChange=(params)=>{
        setTemplate(params.target.value)
    }
    const handleCreateView = async()=>{
        if(templateNameChange==''){
            setShowWarning(true)
        }else{
            var newTemplate = new UserViewSettings();
                    
            switch (template){
                case -1:{
                    const {viewId,viewName,sumValDbString,authSchDbString,virtTribDbString,masterIntListDbString} = store.getState().user;
                    newTemplate.listView= masterIntListDbString;
                    newTemplate.smvView= sumValDbString;
                    newTemplate.scheduleView= authSchDbString;
                    newTemplate.vtsView= virtTribDbString;
                    break;
                }
                case 0:{
                    newTemplate = new UserViewSettings();
                    newTemplate.listView= '';
                    newTemplate.smvView= '';
                    newTemplate.scheduleView= '';
                    newTemplate.vtsView= '';
                    break;
                }
                default:{
                    var found = viewArray.find(ele=>ele.viewId == template)
                    newTemplate.listView= found.listView;
                    newTemplate.smvView= found.smvView;
                    newTemplate.scheduleView= found.scheduleView;
                    newTemplate.vtsView= found.vtsView;
                    break;
                }
            }
            newTemplate.viewId = 0;
            newTemplate.viewName = templateNameChange
            try {
                store.dispatch(setLoading(true));
                var newID = await ApiHelper.CreateView(newTemplate);
                store.dispatch(setLoading(false));
                var settingData = await ApiHelper.GetAllViews();
                if(settingData && settingData.length > 0){
                  //store.dispatch(setSettingsFromDBObject(settingData[0]));
                  store.dispatch(setViewArray(settingData));
                  store.dispatch(setSettingsFromDBObject(settingData.find(ele=>ele.viewId == newID)));
                }
              }catch(error){
                console.log(error)
              } 
            setShowWarning(false)
            setShowNewViewPopup(false)
        }
    }
    useEffect(()=>{
        if(viewID == 0){
            setViewName('Default')
        }
        else{
            var newName = viewArray.find(ele=> ele.viewId == viewID).viewName;
            setViewName(newName)
            setCurrentView(viewID)
        }
    },[viewID])
    const handleClickOption = (elem)=>{
        if(elem==0){
            store.dispatch(resetToDefault())
        }
        else{
            store.dispatch(setSettingsFromDBObject(elem))
            setShowDropdown(false)
        }
    }
    return(
        <>
        <div className="personalisedviewdiv">
            <div className="personalisedviewselectdiv" onMouseEnter={()=>setShowDropdown(true)} onMouseLeave={()=>setShowDropdown(false)}>
                <div  className='viewselecter' >
                    <div className="personalviewselectortext">{viewName}</div>
                    {viewArray.length> 0 &&
                        <div className="cavertdropdown">
                            {showDropdown && <KeyboardArrowUpIcon />}
                            {!showDropdown && <KeyboardArrowDownIcon />}
                        </div>
                    }
                </div>
                {showDropdown && viewArray.length> 0 &&
                    <div className="personalviewoptionsdiv">
                        <div className="personalviewoptionrow">
                        <div className="personalviewoptions" onClick={()=>handleClickOption(0)}>Default</div>
                        <div className="deleteviewdiv"></div>
                        </div>
                        {viewArray.map((element) => (
                            <div className="personalviewoptionrow">
                            <div  className="personalviewoptions" key={element.viewId} onClick={()=>handleClickOption(element)}>{element.viewName}</div>
                            <div className="deleteviewdiv valid" onClick={()=>setElemToDelete(element)}>
                                <div>
                                    <CloseIcon color="error"sx={{height:'24px',width:'24px',':hover':{color:'#d50000'}}}/>
                                </div>
                            </div>
                            </div>
                        ))}
                           
                    </div>
                }
            </div>
            <Button sx={{width:'30px',minWidth:'30px',border:'white 1px solid',borderLeft:'unset',borderRadius:'0px 4px 4px 0px',":hover":{backgroundColor:'#009FE3',border:'white 1px solid'}}} onClick={()=>(setTemplate(-1),setShowNewViewPopup(true))}>
                <AddCircleOutlineIcon sx={{marginLeft:'3px',color:'white'}}/>
            </Button>
        </div>
        {showNewViewPopup&&
            <ClickAwayListener onClickAway={(event) =>  (setShowWarning(false),setShowNewViewPopup(false))}>
                <div className='createviewpopup'>
                    <div className='closeme' onClick={() => (setShowWarning(false),setShowNewViewPopup(false))}>
                    <CloseIcon />
                    </div>
                    <div className='popupheader'>Analyse</div>
                    <div className='popupbody createviewbody'>
                    <label htmlFor="viewname">Name:</label>
                    <input
                    className="viewnameinput"
                    type="text"
                    id="viewnameinput"
                    name="viewnameinput"
                    value={templateNameChange}
                    onChange={(param)=>setTemplateNameChange(param.target.value)}
                    />
                    {showWarning &&
                    <div className="viewerrormessage">Please give the view a name.</div>}
                    {!showWarning &&
                    <div className="viewerrormessage"></div>}
                    <label htmlFor="templatedropdown">Use View as Template:</label>
                    <select name="templatedropdown" className="templatedropdown" value={template} onChange={handleTemplateChange}>
                        <option key={-1} value={-1}>
                            Current
                        </option>
                        <option key={0} value={0}>
                            Default
                        </option>
                        {viewArray.map((element) => (
                            <option key={element.viewID} value={element.viewId}>
                                {element.viewName}
                            </option>
                        ))}
                    </select>
                    </div>
                    <div className="createviewpopupbuttondiv">
                        <Button variant='outlined' className='confirmationmodalbutton' onClick={() => (setShowWarning(false),setShowNewViewPopup(false))}>Cancel</Button>
                        <Button variant='contained' className='confirmationmodalbutton' onClick={() => handleCreateView()}>OK</Button>
                    </div>
                </div>
            </ClickAwayListener>

        }
        {elemToDelete &&
            <ClickAwayListener onClickAway={(event) =>(setElemToDelete(undefined))}>
                <div className='noschemepopup'>
                <div className='closeme' onClick={()=>setElemToDelete(undefined)}>
                <CloseIcon />
                </div>
                    <div className='popupheader'>Analyse</div>
                    <div className='popupbody'>Do you want to delete {elemToDelete.viewName}</div>
                    <div className="createviewpopupbuttondiv">
                        <Button variant='outlined' className='confirmationmodalbutton' onClick={() => (setElemToDelete(undefined))}>Cancel</Button>
                        <Button variant='contained' className='confirmationmodalbutton' onClick={() => handleDeleteView()}>OK</Button>
                    </div>
                </div>
            </ClickAwayListener>
        }
        </>
    )
}