import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'; 
import store from './Redux/ConfigureStore';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {  LocalizationProvider } from "@mui/x-date-pickers-pro";
import 'dayjs/locale/en-gb';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BorderRight } from '@mui/icons-material';

const root = ReactDOM.createRoot(document.getElementById('root'));

let theme = createTheme({
  typography: {
    fontFamily: 'Selawik, sans-serif', 
    fontSize:12,
    h1: {
      fontFamily: 'Selawik, sans-serif',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Selawik, sans-serif',
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'Selawik, sans-serif',
      fontWeight: 500,
    },
    button: {
      fontFamily: 'Selawik, sans-serif',
      textTransform: 'none', 
    },
    checkbox:{
      padding:'0px',
    },
  },
  palette: {
    error: {
      main: '#E73F40', 
    },
    secondary: {
      main: '#009FE3'
    }
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root:{

            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#CDE6F7 !important',
              '&:hover': {
                backgroundColor: '#CDE6F7 !important',
              },
            },
            '& .MuiDataGrid-row': {
              '&:hover': {
                backgroundColor: '#CDE6F7 !important',
              },
            },
            '& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft, .css-lsrao6-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedRight': {
              backgroundColor: '#CDE6F7 !important',
          },
          '& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.odd .MuiDataGrid-cell--pinnedLeft, .css-lsrao6-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.odd .MuiDataGrid-cell--pinnedRight': {
            backgroundColor: '#eeeeee',
        },
        '& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.even .MuiDataGrid-cell--pinnedLeft, .css-lsrao6-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.even .MuiDataGrid-cell--pinnedRight': {
          
            backgroundColor: 'white',
        },
            '& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft, .css-lsrao6-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight': {
              backgroundColor: '#CDE6F7 !important',
          },
          '.MuiDataGrid-cell--pinnedLeft.MuiDataGrid-cell--withRightBorder': {
            borderRight: '2px solid rgb(200, 200, 200)'

        },
        '.MuiDataGrid-columnHeader--pinnedLeft.MuiDataGrid-columnHeader--withRightBorder': {
          borderRight: '2px solid rgb(200, 200, 200)'

      },

        },
        borderColor:'#D5D5D5',
        columnHeader: {
          padding: '0px 5px 0px 5px',
          justifyContent: 'center',
           border: '0.5px solid #D5D5D5',
          color: '#0173C7',
          margin:0,
          overflow: "hidden",
          whiteSpace: "normal",
          textOverflow:"clipped",
          fontWeight:600,
        },
        cell: {
          padding: '0px 5px 0px 5px',
          justifyContent: 'center',
          border: '1px solid #D5D5D5',
          margin:0
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          color: '#009FE3',
          borderRadius: '2px',
          borderWidth: '1px',
          borderColor: '#009FE3',
          border: '1px solid',
          backgroundColor: 'white',      

        }
      },
      
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#009FE3', 
            borderWidth: '1px', 
          },
          '&:hover fieldset': {
            borderColor: '#009FE3',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#009FE3', 
          },
          '&.Mui-disabled fieldset': {
            borderColor: '#009FE3', 
          },
          notchedOutline: {
            borderColor: '#009FE3',
          },
        },
      }
    },
    
    MuiCheckbox:{
      color: '#009FE3',
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 15,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#000', 
          '&.Mui-selected': {
            backgroundColor: '#009FE3',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#009FE3',
              color: '#fff',
            },
          },
          '&:focus': {
            backgroundColor: '#009FE3', 
            color: '#fff',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          borderColor:'#009FE3',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor:'#009FE3'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          borderColor:'#009FE3',
          
          backgroundColor:'#009FE3'
        }
      }
    }
  }
});
theme = createTheme(theme, {
  palette: {
    lightblue: theme.palette.augmentColor({
      color: {
        main: '#009FE3',
      },
      name: 'lightblue',
    }),
  },
});
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  // </React.StrictMode>
);


reportWebVitals();
