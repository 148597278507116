import { formatDateForApi } from "../../Services/FormatService";
import { DateField } from "../Enums/DateField";
import { DateLogic } from "../Enums/DateLogic";
import { SearchLogic } from "../Enums/SearchLogic";
import { StatusList } from "../Enums/StatusList";
import { ValuationTribunalVTS } from "../ValuationTribunalVTS";

export default class ValuationTribunalVTSApiModel{
    agentLogic?: boolean;
    agentName?: string;
    appealNo?: string;
    sprogID?: string;
    decisionLogic?: boolean;
    decisionValue?: string;
    listYear?: number;
    vtsStatus?: StatusList;
    goneOntoLandsTribunal?: boolean;
    showFullDecisionsOnly?: boolean;
    dateSearch?: boolean;
    dateField?: DateField;
    dateLogic?: DateLogic;
    dateValue?: string;
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?:number;
    constructor(data:Partial<ValuationTribunalVTS>)
    {
        this.agentName = data.agentName?data.agentName:undefined;
        this.agentLogic = data.agentName? true:undefined;
        this.appealNo =  data.appealNo?data.appealNo:undefined;
        this.sprogID = data.sprogID? data.sprogID:undefined;
        this.decisionValue = data.decisionValue? data.decisionValue:undefined;
        this.decisionLogic = data.decisionValue? true:undefined;
        this.listYear = data.listYear?data.listYear:undefined;
        this.dateSearch = data.dateSearch? true:undefined;
        this.dateField =  data.dateSearch?Number(data.dateField):undefined;
        this.dateLogic = data.dateSearch? Number(data.dateLogic):undefined;
        this.dateValue = data.dateSearch && data.dateValue? formatDateForApi(data.dateValue):undefined;
        this.rvSearch = data.rvSearch?data.rvSearch:undefined;
        this.rvLogic =  data.rvSearch?Number(data.rvLogic):undefined;
        this.rvValue = data.rvSearch?  Number(data.rvValue):undefined;
        this.rvValueLessThan = data.rvSearch && Number(data.rvLogic) == 3? Number(data.rvValueLessThan):undefined;
        this.vtsStatus= data.vtsStatus && Number(data.vtsStatus) != 0 ?  Number(data.vtsStatus):undefined;
        this.goneOntoLandsTribunal = data.goneOntoLandsTribunal ? data.goneOntoLandsTribunal:undefined;
        this.showFullDecisionsOnly = data.showFullDecisionsOnly ? data.showFullDecisionsOnly:undefined;
    }
}