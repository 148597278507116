import { ViewList } from "@mui/icons-material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
const initialState = {
    intListVisibility:{},
    intListOrder:[],
    intListDimensions:{},
    intListDbString:'',
    sumValVisibility:{},
    sumValOrder:[],
    sumValDimensions:{},
    sumValDbString:'',
    authSchVisibility:{},
    authSchOrder:[],
    authSchDimensions:{},
    authSchDbString:'',
    virtTribVisibility:{},
    virtTribOrder:[],
    virtTribDimensions:{},
    virtTribDbString:'',
    viewId: 0,
    viewName:'',
    listBuilderDbString:'',
    masterIntListDbString:'',
    riverlakeDbString:'',
    viewArray:[],
    triggerExport: false,
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIntListSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,intListDbString} = action.payload
            
            return{
                ...state,
                intListVisibility : columnVisibilityModel,
                intListOrder : orderedFields,
                intListDimensions : dimensions,
                intListDbString: intListDbString,
                masterIntListDbString:intListDbString,
                triggerExport:true,
            }
        },
        setListBuilderSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,listBuilderDbString} = action.payload
            
            return{
                ...state,
                intListVisibility : columnVisibilityModel,
                intListOrder : orderedFields,
                intListDimensions : dimensions,
                masterIntListDbString: listBuilderDbString,
                listBuilderDbString: listBuilderDbString,
                triggerExport:true,
            }
        },
        setRiverlakeSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,riverlakeDbString} = action.payload
            
            return{
                ...state,
                intListVisibility : columnVisibilityModel,
                intListOrder : orderedFields,
                intListDimensions : dimensions,
                masterIntListDbString: riverlakeDbString,
                riverlakeDbString: riverlakeDbString,
                triggerExport:true,
            }
        },
        setAuthSchSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,authSchDbString} = action.payload
            
            return{
                ...state,
                authSchVisibility : columnVisibilityModel,
                authSchOrder : orderedFields,
                authSchDimensions : dimensions,
                authSchDbString: authSchDbString,
                triggerExport:true,
            }
        },
        setSumValSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,sumValDbString} = action.payload
            
            return{
                ...state,
                sumValVisibility : columnVisibilityModel,
                sumValOrder : orderedFields,
                sumValDimensions : dimensions,
                sumValDbString:sumValDbString,
                triggerExport:true,
            }
        },
        setVirtTribSettings(state,action){
            const {dimensions,orderedFields,columnVisibilityModel,virtTribDbString} = action.payload
            
            return{
                ...state,
                virtTribVisibility : columnVisibilityModel,
                virtTribOrder : orderedFields,
                virtTribDimensions : dimensions,
                virtTribDbString:virtTribDbString,
                triggerExport:true,
            }
        },
        setViewId(state,action){
            state.viewId = action.payload;
        },
        setViewArray(state,action){
            state.viewArray = action.payload;
        },
        setSettingsFromDBObject(state,action){
            const {viewName,listView,smvView,scheduleView,vtsView,viewId} = action.payload;
            const parseJson = (str) => {
                try {
                    return JSON.parse(str);
                } catch (error) {
                    console.error("Failed to parse JSON:", str, error);
                    return {};
                }
            };
        
            const intlist = listView ? parseJson(listView) : {};
            const smv = smvView ? parseJson(smvView) : {};
            const schedule = scheduleView ? parseJson(scheduleView) : {};
            const vts = vtsView ? parseJson(vtsView) : {};
            return {
                ...state,
                intListVisibility: intlist.columnVisibilityModel || {},
                intListOrder: intlist.orderedFields || [],
                intListDimensions: intlist.dimensions || {},
                listBuilderDbString:'',
                riverlakeDbString:'',
                intListDbString:'',
                masterIntListDbString: listView || "",
                sumValVisibility: smv.columnVisibilityModel || {},
                sumValOrder: smv.orderedFields || [],
                sumValDimensions: smv.dimensions || {},
                sumValDbString: smvView || "",
                authSchVisibility: schedule.columnVisibilityModel || {},
                authSchOrder: schedule.orderedFields || [],
                authSchDimensions: schedule.dimensions || {},
                authSchDbString: scheduleView || "",
                virtTribVisibility: vts.columnVisibilityModel || {},
                virtTribOrder: vts.orderedFields || [],
                virtTribDimensions: vts.dimensions || {},
                virtTribDbString: vtsView || "",
                viewId: viewId,
                viewName: viewName,
                triggerExport:false,
            };

        },
        reset(state){
            return{
                ...state,
                intListVisibility:{},
                intListOrder:[],
                intListDimensions:{},
                intListDbString:'',
                sumValVisibility:{},
                sumValOrder:[],
                sumValDimensions:{},
                sumValDbString:'',
                authSchVisibility:{},
                authSchOrder:[],
                authSchDimensions:{},
                authSchDbString:'',
                virtTribVisibility:{},
                virtTribOrder:[],
                virtTribDimensions:{},
                virtTribDbString:'',
                viewId: 0,
                viewName:'',
                listBuilderDbString:'',
                masterIntListDbString:'',
                riverlakeDbString:'',
                viewArray:[],
                triggerExport: false,
            }
        },
        resetToDefault(state){
            return {
                ...state,
            intListVisibility:{},
            intListOrder:[],
            intListDimensions:{},
            intListDbString:'',
            sumValVisibility:{},
            sumValOrder:[],
            sumValDimensions:{},
            sumValDbString:'',
            authSchVisibility:{},
            authSchOrder:[],
            authSchDimensions:{},
            authSchDbString:'',
            virtTribVisibility:{},
            virtTribOrder:[],
            virtTribDimensions:{},
            virtTribDbString:'',
            viewId: 0,
            viewName:'',
            riverlakeDbString:'',
            listBuilderDbString:'',
            masterIntListDbString:'',
            triggerExport:false,
        }
    },
    }
});
export const { resetToDefault,setViewArray,setViewId,setListBuilderSettings,setRiverlakeSettings,setSumValSettings,setSettingsFromDBObject,setIntListSettings,reset,setVirtTribSettings,setAuthSchSettings} = userSlice.actions;
export default userSlice.reducer;



export const selectViewID = state => state.user.viewId;
export const selectIntListDbString= state => state.user.intListDbString;
export const selectSumValDbString= state => state.user.sumValDbString;
export const selectAuthSchDbString= state => state.user.authSchDbString;
export const selectListBuilderDbString= state => state.user.listBuilderDbString;
export const selectVirtTribDbString= state => state.user.virtTribDbString;
export const selectMasterIntListDbString = state => state.user.masterIntListDbString;
export const selectRiverlakeDbString = state =>state.user.riverlakeDbString;
export const selectViewArray= state => state.user.viewArray;