import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsRiverlakeUser } from "../../../Redux/AuthorisationSlicer";

const RightClickOptions = {
  QuickPrintDAR: { id: 4, label: "Quick Print DAR", icon: "reporticon" },
  ShowValuation: { id: 5, label: "Show Valuation", icon: "reporticon" },
  ExportToRiverlake:{id:8,label:'Export to Riverlake', icon: "riverlakeicon"},
  DeleteAssessments: { id: 0, label: "Delete Assessment(s)", icon: "deleterowicon" },
  ShowEntryOnGOVUK: { id: 2, label: "Show Entry on GOV.UK", icon: "showgovicon" },
  ShowScheme: { id: 1, label: "Show Scheme", icon: "showschemeicon" },
 }
export default function ListBuilderRightClickMenu({ contextMenuPosition, handleClose, handleMenuItemClick  }) {

    const isContextMenuOpen = contextMenuPosition.mouseY !== null && contextMenuPosition.mouseX !== null;
    const isRiverlakeUser = useSelector(selectIsRiverlakeUser)
    const handleMenuClose = () => {
      handleClose();
    };
  
    const handleMenuClick = (item) => {
      handleMenuItemClick(item);
      handleClose();
    };
    const handleBackgroundRightClick = (event)=>{
      handleClose();
    }
    
    return (<>

      <Menu
        open={isContextMenuOpen}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          isContextMenuOpen
            ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
            : undefined
        }
        MenuListProps={{
          onClick: (event) => event.stopPropagation(), 
          onContextMenu: (event) => {
            event.preventDefault(); 
            handleMenuClose(); 
          },
        }}
      >
          {Object.values(RightClickOptions).map((element) => (    
              (isRiverlakeUser || element.id != 8)&&                  
              <MenuItem sx={{p:'0px 4px 0px 4px'}} onClick={() => handleMenuClick(element.label)} key={element.id}>
                <span className={"rightclickicon "+element.icon}></span>
                <span className="rightclicktext">{element.label}</span>
              </MenuItem>                
          ))}
      </Menu>
      </>
    );
  };
  