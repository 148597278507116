
import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import { setDisplayRiverlake, emptyRiverlakeArray, resetRiverlakeTableSort, selectRLHasUnsavedChanges, selectListLength, selectPinColumns, selectShowRiverlakeDetails, setTriggerRiverlakeDeleteSelection, setRiverlakePinColumns, selectHeaderBarContext, selectRLSendToRiverlakeID, setHeaderBarContext, } from "../../Redux/RiverlakeSlicer";
import { useSelector } from "react-redux";
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { Button, Checkbox, FormControlLabel, Radio, Snackbar } from "@mui/material";

import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { stringifyList } from "../../Services/FormatService";
import DoneIcon from '@mui/icons-material/Done';
import { SelectIsLoading, setLoading } from "../../Redux/SearchSlicer";
import SendToRiverlake from "../../Models/SendToRiverLake";
import Select from "react-select";
import BasicCodeModel from "../../Models/BasicCodeModel";
import { RLClient } from "../../Models/RLClient";
import ExportSession from "../../Models/ExportSession";
enum FileOptions {
    'New' = 0,
    'Begin Export To Riverlake' = 1,
    'Save'=2,
}



enum ToolsOptions {
    'Delete Record' = 7,
    'Grid Lock' = 8
}
export default function RiverlakeBarComponent(props) {
    const showRiverlakeDetails = useSelector(selectShowRiverlakeDetails);
    const [popUpContext, setPopUpContext] = useState<string | null>(null);
    const riverlakeCount = useSelector(selectListLength);
    const [label, setLabel] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>();
    const rlSendToRiverlakeID = useSelector(selectRLSendToRiverlakeID);
    const unsavedChanges = useSelector(selectRLHasUnsavedChanges);
    const pinColumns = useSelector(selectPinColumns);
    const [exportListPopOver, setExportListPopOver] = useState(false);
    const [clientDropdown,setClientDropdown] = useState<BasicCodeModel[]>([]);
    const [clientDropdownValue,setClientDropdownValue] = useState<BasicCodeModel|null>();
    const [exportYear,setExportYear]= useState(2023)
    const [summarys,setSummarys] = useState<SendToRiverlake[]>()
    const [labelDuplicate,setLabelDuplicate]= useState(false)

  useEffect(()=>{
    getSummaries()
    getClients()
  },[])

  const getSummaries=async()=>{

    try{
        var response = await ApiHelper.GetSendToRiverlake()
        setSummarys(response)

    }
    catch(error){
        console.log(error)
    }
  }

  const getClients=async()=>{
    try{
      var clients = await ApiHelper.GetRLClients()
      if(clients){
        var formattedclients = clients.map(client => 
            (
                new BasicCodeModel(
                    {value: client.remoteClientID!,
                    label: client.clientName!}
                )
            ));
        setClientDropdown(formattedclients)
      }
    }
    catch(e){
      console.log(e)
    }
  }
    const handleCloseExportListPopOver=()=>{
        setClientDropdownValue(undefined)
        setLabel('')
        setExportListPopOver(false)
    }
    const handleFileOptionsClick = (index: FileOptions) => {
        switch (index) {
            case 0: {
                if (unsavedChanges) {
                    setPopUpContext(FileOptions[0])
                }
                else {
                    store.dispatch(emptyRiverlakeArray());
                    setPopUpContext(null);
                }
                break;
            }
            case 1: {
                setExportListPopOver(true)
                break;
            }
            case 2: {
                handleSaveSendToRiverlake()
                break;
            }
        }
    }
    const handleOKNew=()=>{
        store.dispatch(emptyRiverlakeArray());
        setPopUpContext(null);
    }
    const handleSendtoRiverlake = async() => {
        try {
            var newSendToRiverlake = new SendToRiverlake()
            newSendToRiverlake.remoteClientID = Number(clientDropdownValue!.value)
            newSendToRiverlake.uarn = stringifyList(store.getState().riverlake.listList)
            newSendToRiverlake.listYear = exportYear;
            newSendToRiverlake.label = label!
            console.log(summarys)
            console.log(summarys)
            if(summarys!.findIndex(sum=>sum.label == label!)<0){
                var response = await ApiHelper.SendToRiverlakeFunc(newSendToRiverlake);
                if(response){
                    var session = await handleCreateExport(response,newSendToRiverlake.remoteClientID,exportYear)
                
                }
                handleCloseExportListPopOver();
                store.dispatch(setHeaderBarContext('sessions'))
            }
            else{
                setLabelDuplicate(true)
            }
        }
        
        catch(error) {
            console.error(error)
        }
    }
        const handleCreateExport = async(rlSendToRiverlakeID,remoteClientID,exportYear)=>{
            try{
                var session = new ExportSession()
                session.sendToRLID = rlSendToRiverlakeID
                session.remoteClientID = remoteClientID
                session.listYear = exportYear
                var response = await ApiHelper.CreateExportSession(session)
                return session
    
            }
            catch(e){
                console.log(e)
            }
        }
    const handleSaveSendToRiverlake = async()=>{
        try {
            var request = new SendToRiverlake()
            request.rlSendToRiverlakeID = rlSendToRiverlakeID
            var response = await ApiHelper.GetSingleSendToRiverlakeInternal(request)
            if(response){
                response.uarn = stringifyList(store.getState().riverlake.listList)
                await ApiHelper.UpdateSendToRiverlake(response)
                store.dispatch(emptyRiverlakeArray());
                store.dispatch(setHeaderBarContext('sessions'))
            }
            
        }
        catch(error) {
            console.error(error)
        }
    }

    const handleRadioChange = (e) => {
        const {  value } = e.target;


        setExportYear(value);
    }

    useEffect(() => {
        if (snackbarSeverity) {
            setOpenSnackbar(true)
        }
        else {
            setOpenSnackbar(false)

        }
    }, [snackbarSeverity]);

    const selectStyling = {
        control: (baseStyles, state) => ({
          ...baseStyles,
          border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
          minHeight:'21px',
          height:'21px',
          borderRadius:'0px',
      }),
      indicatorsContainer:(baseStyles, state) => ({
          ...baseStyles,
          minHeight:'22px',
          height:'22px'
      }),
      indicatorSeparator:(baseStyles, state) => ({
          ...baseStyles,
          display:'none',
      }),
      valueContainer:(baseStyles,state) => ({
          ...baseStyles,
          padding:'0px 0px 0px 0px',
          minHeight:'22px',
          height:'22px',
          border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
      }),
      clearIndicator: (baseStyles,state) => ({
        ...baseStyles,
        
        padding:state.isFocused ? 0: state.isSelected ? 0:state.menuIsOpen ? 0:0,
        }),
      }
     const handleChange=(e)=>{
        var value = '';
        if(e!=null){
            value = e.value
        }
        setClientDropdownValue(e);
     }


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        setSnackbarSeverity(undefined)
    }
    return (<>
        <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className={riverlakeCount > 0 ? 'navbartableelement' : 'navbartableelement disabled'} onClick={(e) =>{riverlakeCount > 0 && handleFileOptionsClick(0)}}>
                    <figure className="icon newfileicon"></figure>
                    <div className="navbartableelementtext">{FileOptions[0]}</div>
                </div>
                {rlSendToRiverlakeID ==0&&<div className={riverlakeCount > 0 ? 'navbartableelement' : 'navbartableelement disabled'} onClick={(e) =>{riverlakeCount > 0 && handleFileOptionsClick(1)}}>
                    <figure className="icon riverlakeicon"></figure>
                    <div className="navbartableelementtext">{FileOptions[1]}</div>
                </div>}
                {rlSendToRiverlakeID >0 &&<div className={unsavedChanges? 'navbartableelement' : 'navbartableelement disabled'} onClick={(e) =>{unsavedChanges && handleFileOptionsClick(2)}}>
                    <figure className="icon saveicon"></figure>
                    <div className="navbartableelementtext">{FileOptions[2]}</div>
                </div>}
            </div>
            <div className="navbartableselectiondescription">
                <div>File</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        <div className="vhr">
        </div>
        <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className={riverlakeCount > 0 ? 'navbartableelement' : 'navbartableelement disabled'} onClick={() => {riverlakeCount > 0 &&store.dispatch(setTriggerRiverlakeDeleteSelection(true))}}>
                    <figure className="icon deleterowicon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[7]}</div>
                </div>
                <div className={pinColumns ? 'navbartableelement selected' : 'navbartableelement'} onClick={() => store.dispatch(setRiverlakePinColumns(!pinColumns))}>
                    <figure className="icon gridlockicon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[8]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>Export</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        {exportListPopOver == true &&
            <div className="openlistbuilderPopup layoutcolumn">
                <div className="headerdiv" >
                    <div className="header">Export To Riverlake</div>
                </div>
                <div className="rllistexporterpopoverbody">
                    <div className="layoutrow">
                        <label htmlFor='rllabelfield' className="rlexporterpopoverlabel">Name:</label>
                        <input className="rllabelfield" name="rllabelfield" value={label} onChange={(e)=>setLabel(e.target.value)} placeholder="Enter a name.."/>
                        
                    </div>
                    {labelDuplicate&&<div className="rllabelfielderrormessage">This name already exists, please pick another</div>}
                    <div className="layoutrow">
                        <label htmlFor='clientid' className="rlexporterpopoverlabel">To Client:</label>
                        <Select  name="clientid" onChange={(e) => handleChange(e)} isClearable  id="dropdown" styles={selectStyling} className='rlclientfield' isLoading={clientDropdown == undefined || clientDropdown.length == 0} value={clientDropdownValue ? clientDropdownValue : ''} isSearchable options={clientDropdown} />
                    </div>
                    <div className="exportlistyear">
                            <div className="exportyearlabel">List Year:</div>
                        <div className="radiogroup ">
                        
                            <FormControlLabel value="best" control={
                                <Radio
                                    checked={exportYear == 2023}
                                    onClick={handleRadioChange}
                                    name='exportYear'
                                    value={2023}
                                    size="small"
                                    inputProps={{ 'aria-label': '2023' }}
                                    sx={{ fontSize: '12px' }}

                                />}
                                label="2023" />

                            <FormControlLabel value="best" control={
                                <Radio
                                    checked={exportYear == 2017}
                                    onClick={handleRadioChange}
                                    name='exportYear'
                                    value={2017}
                                    size="small"
                                    inputProps={{ 'aria-label': '2017' }}
                                    sx={{ fontSize: '12px'}}
                                />}
                                label="2017" />
                        </div>
                    </div>
                </div>
                <div className="filelistbuilderbuttondiv">
                    <Button className='openlistbuilderbutton' disabled={label== undefined || label == ''||clientDropdownValue == null ||clientDropdownValue == undefined}  sx={{ marginLeft: '5px' }} variant="contained" onClick={() => handleSendtoRiverlake()}>Create Export Session</Button>
                     <Button className='openlistbuilderbutton' variant="outlined" onClick={() => (handleCloseExportListPopOver())}>Cancel</Button> 
                </div>

            </div>
        }        
        {(popUpContext == FileOptions[0]) &&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">Are you sure?</div>
                <div className="confirmationmodaldiv">You will lose all unexported changes.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => handleOKNew()}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (setPopUpContext(null))}>Cancel</button>
                </div>
            </div>
        }
        <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert severity={snackbarSeverity} onClose={handleCloseSnackbar} sx={{ width: '100%' }}>
                {snackbarSeverity == 'success' && <span>Changes saved successfully!</span>}
                {snackbarSeverity == 'error' && <span>Save failed!</span>}
            </MuiAlert>
        </Snackbar>
    </>
    )
}