import { useEffect, useState } from "react";
import { TableSelection } from "../../Models/Enums/TableSelection";
import store from "../../Redux/ConfigureStore";
import { setSummaryListYear, setTableContext,setSVIsLive, selectSVIsLive, selectTableContext, selectListYear } from "../../Redux/SearchSlicer";
import { cleanup } from "@testing-library/react";
import { useSelector } from "react-redux";
import { selectDisplayListBuilder } from "../../Redux/ListBuilderSlicer";
import { selectDisplayRiverlake, selectListLength, setDisplayRiverlake } from "../../Redux/RiverlakeSlicer";
import { selectIsRiverlakeUser } from "../../Redux/AuthorisationSlicer";

export default function SearchBarComponent(props){
    const listYear = useSelector(selectListYear);
    const table = useSelector(selectTableContext);
    const{handleSearchClick,handleClearClick} = props;
    const isLive = useSelector(selectSVIsLive);
    const displayListBuilder = useSelector(selectDisplayListBuilder);
    const displayRiverlake = useSelector(selectDisplayRiverlake);
    const isRiverlakeUser = useSelector(selectIsRiverlakeUser)
    const rlListLength = useSelector(selectListLength)
    const handleRiverlakeClick=()=>{
        store.dispatch(setDisplayRiverlake(displayRiverlake? false:true))
    }

    useEffect(() => {
        store.dispatch(setSummaryListYear(listYear));
      }, [listYear]);
    
    return(
        <>
            <div className='navbarsearchsection'>
                <div className='navbarsearchselection'>
                    <div className='navbarsearchsectionelement' onClick={handleSearchClick}>
                        <figure className="icon searchicon"></figure>
                        <div>Search</div>
                    </div>
                    <div className='navbarsearchsectionelement' onClick={handleClearClick}>
                    <figure className="icon clearicon"></figure>
                        <div>Clear All</div>
                    </div>
                    {isRiverlakeUser&&
                        <div className='navbarsearchsectionelement' onClick={handleRiverlakeClick}>
                            {rlListLength > 0 &&
                            <div className="listbuildercount">
                                {rlListLength}
                            </div>}
                        <figure className="icon riverlakeicon"></figure>
                            <div>Riverlake</div>
                        </div>
                    }
                    {!isRiverlakeUser&&
                    <div className='navbarsearchsectionelement disabled'>
                    <figure className="icon riverlakeicon"></figure>
                        <div>Riverlake</div>
                    </div>}
                </div>
                <div className="navbartableselectiondescription">
                    <div>Search Functions</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            <div className="navbartablesection">
                <div className="navbartableselection">
                    <div className={`navbartableelement ${table === 0 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setTableContext(0))}>
                    <figure className="icon databaseicon"></figure>
                        <div className="navbartableelementtext">Integrated List</div>
                    </div>
                    <div className={`navbartableelement ${table === 1 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setTableContext(1))}>
                    <figure className="icon databaseicon"></figure>
                        <div className="navbartableelementtext">Summary Valuation</div>
                    </div>
                    <div className={`navbartableelement ${table === 2 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setTableContext(2))}>
                    <figure className="icon databaseicon"></figure>
                        <div className="navbartableelementtext">Authority Schedule</div>
                    </div>
                    <div className={`navbartableelement ${table === 3 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setTableContext(3))}>
                    <figure className="icon databaseicon"></figure>
                        <div className="navbartableelementtext">Valuation Tribunal</div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>Main Search Database Selection</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            {table === 1 &&
            <>
            <div className="navbaryearsection">
                <div className="navbaryearrowsection">
                    <div className="archivesection">
                        <div className={`navbaryearelement ${isLive === true ? 'selected' : ''}`}  onClick={()=>store.dispatch(setSVIsLive(true))}>
                            <figure className="icon liveicon"></figure>
                            <div className="navbaryearelementtext">Live</div>
                        </div>
                        <div className={`navbaryearelement ${isLive === false ? 'selected' : ''}`} onClick={()=>store.dispatch(setSVIsLive(false))}>
                            <figure className="icon archiveicon"></figure>
                            <div className="navbaryearelementtext">Archive</div>
                        </div>
                        <div className="vhr"></div>
                    </div> 
                    <div className="navbaryearselection">
                        <div className={`navbaryearelement ${listYear === 2023 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setSummaryListYear(2023))}>
                            <figure className="icon list2023icon"></figure>
                            <div className="navbaryearelementtext">2023</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2017 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2017))}>
                            <figure className="icon list2017icon"></figure>
                            <div className="navbaryearelementtext">2017</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2010 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2010))}>
                            <figure className="icon list2010icon"></figure>
                            <div className="navbaryearelementtext">2010</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2005 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2005))}>
                            <figure className="icon list2005icon"></figure>
                            <div className="navbaryearelementtext">2005</div>
                        </div>
                    </div>  
                </div>
                <div className="navbartableselectiondescription">
                    <div>SMV</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            </>
            }
        </>
    )
}