
import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import { displayListBuilder, emptyListBuilderArray, resetListBuilderTableSort, selectHasUnsavedChanges, selectListLength, selectPinColumns, selectShowListBuilderDetails, setChangesMade, setDispatchAddSelection,  setListFromApi,  setPinColumns,  setShowListBuilderDetails, setTriggerDeleteSelection, setTriggerExportExcel, setTriggerShowScheme } from "../../Redux/ListBuilderSlicer";
import { useSelector } from "react-redux";
import { setTriggerGOVUK, setTriggerGoogleMaps } from '../../Redux/ListBuilderSlicer';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import ListBuilderMetaData from "../../Models/ListBuilderMetaData";
import { Button, Checkbox, Snackbar } from "@mui/material";
import UpdateListBuilder from "../../Models/UpdateListBuilder";
import SaveListBuilder from "../../Models/SaveListBuilder";
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { stringifyList } from "../../Services/FormatService";
import DoneIcon from '@mui/icons-material/Done';
import { SelectIsLoading, setLoading } from "../../Redux/SearchSlicer";
enum FileOptions {
    'New' = 0,
    'Open' = 1,
    'Save' = 2,
    'Save as..' = 3
}

enum DetailsOptions {
    'Detail' = 0,
    'Clear Sorting' = 1,
}


enum ToolsOptions {
    'Map in Browser' = 0,
    'Show Entry GOV.UK' = 1,
    'Show Scheme' = 2,
    'Quick Excel Export All' = 4,
    'Quick Excel Export Selected' = 5,
    'Clear Sorting' = 6,
    'Delete Record' = 7,
    'Grid Lock'=8
}
export default function ListBuilderBarComponent(props) {
    const showListBuilderDetails = useSelector(selectShowListBuilderDetails);
    const [popUpContext, setPopUpContext] = useState<string | null>(null);
    const [savedLists, setSavedLists] = useState<Array<ListBuilderMetaData>>([]);
    const [selectedList, setSelectedList] = useState<ListBuilderMetaData>();
    const listBuilderCount = useSelector(selectListLength);
    const [saveAsName,setSaveAsName] = useState('');
    const [confirmOverWritePopUp,setConfirmOverWritePopUp] = useState(false);
    const [openSnackbar,setOpenSnackbar]= useState(false);
    const [snackbarSeverity,setSnackbarSeverity]= useState<AlertColor>();
    const [sharable,setSharable]= useState(0);
    const isLoading=useSelector(SelectIsLoading)
    const unsavedChanges = useSelector(selectHasUnsavedChanges);
    const [confirmDeletePopUp,setConfirmDeletePopUp] = useState(false);
    const pinColumns = useSelector(selectPinColumns);

    const handleFileOptionsClick = (index: FileOptions) => {
        switch (index) {
            case 0: {
                if(unsavedChanges){
                    setPopUpContext(FileOptions[0])
                }
                else{
                    store.dispatch(emptyListBuilderArray());
                    setPopUpContext(null);
                }
                break;
            }
            case 1: {
                getSavedLists()
                setPopUpContext(FileOptions[1])
                break;
            }
            case 2: {
                if(listBuilderCount == 0){
                    break;
                }
                handleListSave(store.getState().listBuilder.listID)
                break;
            }
            case 3: {
                if(listBuilderCount == 0){
                    break;
                }
                getSavedLists()
                setPopUpContext(FileOptions[3])

                break;
            }
        }
    }

    const handleListSave=async(listID)=>{
        var uarns = stringifyList(store.getState().listBuilder.listList)
        store.dispatch(setLoading(true))

        if( listID !=''){
            try{
                var data = new UpdateListBuilder()
                data.cid = store.getState().listBuilder.cid;
                data.sharable = sharable;
                data.listID = listID;                
                if(saveAsName !=''){
                    data.label  =saveAsName;
                }
                else{
                    data.label  =store.getState().listBuilder.label;
                }
                data.uarns = uarns;
                var response = await ApiHelper.UpdateList(data)
                setOpenSnackbar(true);
                if(response){
                    store.dispatch(setChangesMade(false));
                    setSnackbarSeverity('success')
                    handleCloseFilePopup()
                }
            }
            catch(e){
                setSnackbarSeverity('error')
            }
            finally{
                
                store.dispatch(setLoading(false));
            }
            
        }
        else{
            try{
                var savedata = new SaveListBuilder()
                if(saveAsName !=''){
                    savedata.label  =saveAsName;
                }
                savedata.sharable= sharable;
                savedata.uarns = uarns;
                var response = await ApiHelper.SaveList(savedata)
                if(response){
                    store.dispatch(setChangesMade(false));
                    setSnackbarSeverity('success')
                    handleCloseFilePopup()
                }
            }
            catch(e){
                console.log(e)
                setSnackbarSeverity('error')
            }            
            finally{
                
                store.dispatch(setLoading(false));
            }
        }
    }
    const handleCloseFilePopup=()=>{
        setSaveAsName('');
        setSelectedList(undefined);
        setSharable(0);
        setConfirmOverWritePopUp(false);
        setPopUpContext(null);
    }
    useEffect(() => {
        if(snackbarSeverity){
            setOpenSnackbar(true)
        }
        else{
            setOpenSnackbar(false)

        }
    }, [snackbarSeverity]);
    const handleDetailsOptionsClick = (index: FileOptions) => {
        switch (index) {
            case 0: {
                setPopUpContext(FileOptions[0])
                break;
            }
            case 1: {
                setPopUpContext(FileOptions[1])
                break;
            }
        }
    }
    const handleExportClick = (index: ToolsOptions) => {
        switch (index) {
            case 0: {
                store.dispatch(setTriggerGoogleMaps(true));
                break;
            }
            case 1: {
                store.dispatch(setTriggerGOVUK(true));
                break;
            }
            case 2: {
                store.dispatch(setTriggerShowScheme(true));
                break;
            }
            
            case 4: {
                store.dispatch(setTriggerExportExcel(2))
                break;
            }
            
            case 5: {
                store.dispatch(setTriggerExportExcel(1))
                break;
            }
        }
    }
    const handleShowDetails = () => {
        if (listBuilderCount > 0) {
            store.dispatch(setShowListBuilderDetails(!showListBuilderDetails))

        }
    }
    const handleClearSort = () => {
        store.dispatch(resetListBuilderTableSort(true));

    }
    const handleDetailsClearSort = () => {

    }
    const handleOkNewClick = (context) => {
        if(context =='openunsaved'){
            handleOpenClick(true)
        }
        else{
            store.dispatch(emptyListBuilderArray());
        }
            setPopUpContext(null);
    }

    const handleOpenClick=async(confirmed)=>{
        if(!unsavedChanges||confirmed){
        try{        
            var data = await ApiHelper.LoadList(selectedList?.listID)
            if(data){
                store.dispatch(setListFromApi(data))   
            }
            
        }
        catch (e:any) {
            console.error(e)
        }
            setPopUpContext(null)
        }
        else{
            setPopUpContext('openunsaved')
        }
    }

    const getSavedLists = async () => {
        try {        
            store.dispatch(setLoading(true))
            var data = await ApiHelper.LoadAllLists()
            data = data ? data : []
            //data = [new ListBuilderMetaData({ cid: '1', name: 'football', listID: 2 }), new ListBuilderMetaData({ cid: '1', name: 'example', listID: 5 })];

            if (data.length > 0) {
                setSavedLists(data);
            }
        }
        catch (e:any) {
            console.error(e)        
            setPopUpContext(null)
        }
        finally{
            store.dispatch(setLoading(false))
        }
    }
    const handleDelete = async()=>{
        try{
            var response = await ApiHelper.DeleteList(selectedList?.listID);
            await getSavedLists()
            setSelectedList(undefined)
        }
        catch(error){
            console.error(error)
        }
        finally{
            store.dispatch(setLoading(false))

        }
    }
    const handleSaveAsClick=()=>{
        if(savedLists.some(list => list.label == saveAsName)){
            setConfirmOverWritePopUp(true)
        }else{
            handleListSave('')
        }
    }

    const  handleOkOverWriteClick = async()=>{
            var overwriteList = await savedLists.find(item => item.label === saveAsName)
            handleListSave(overwriteList?.listID)
    }

    const handleCloseSnackbar = ()=>{
        setOpenSnackbar(false);
        setSnackbarSeverity(undefined)
    }
    return (<>
        <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className='navbarsearchsectionelement' onClick={(e) => handleFileOptionsClick(0)}>
                <figure className="icon newfileicon"></figure>
                    <div>{FileOptions[0]}</div>
                </div>
                <div className='navbarsearchsectionelement' onClick={(e) => handleFileOptionsClick(1)}>
                <figure className="icon openfileicon"></figure>
                    <div>{FileOptions[1]}</div>
                </div>
                <div className={listBuilderCount > 0 ? 'navbarsearchsectionelement' : 'navbarsearchsectionelement disabled'} onClick={(e) => {handleFileOptionsClick(2)}}>
                <figure className="icon saveicon"></figure>
                    <div>{FileOptions[2]}</div>
                </div>
                <div className={listBuilderCount > 0 ? 'navbarsearchsectionelement' : 'navbarsearchsectionelement disabled'} onClick={(e) => {handleFileOptionsClick(3)}}>
                <figure className="icon saveasicon"></figure>
                    <div>{FileOptions[3]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>File</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className={showListBuilderDetails ? 'navbarsearchsectionelement selected' : listBuilderCount > 0? 'navbarsearchsectionelement':'navbarsearchsectionelement disabled'} onClick={handleShowDetails}>
                    <figure className="icon detailicon"></figure>
                    <div>{DetailsOptions[0]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbarsearchsectionelement':'navbarsearchsectionelement disabled'} onClick={handleDetailsClearSort} >
                    <figure className="icon sorticon"></figure>
                    <div>{DetailsOptions[1]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>Detail Table</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'} onClick={(e) => handleExportClick(0)}>
                    <figure className="icon googlemapsicon"></figure>
                    <div className="navbartableelementtext">{ToolsOptions[0]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'} onClick={(e) => handleExportClick(1)}>
                    <figure className="icon showgovicon"></figure>
                    <div className="navbartableelementtext">{ToolsOptions[1]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'} onClick={(e) => handleExportClick(2)}>
                    <figure className="icon showschemeicon"></figure>
                    <div className="navbartableelementtext">{ToolsOptions[2]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'} onClick={(e) => handleExportClick(4)}>
                <figure className="icon showexcelexporticon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[4]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement wide':'navbartableelement wide disabled'} onClick={(e) => handleExportClick(5)}>
                <figure className="icon showexcelexporticon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[5]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'}  onClick={()=>handleClearSort()}>
                    <figure className="icon sorticon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[6]}</div>
                </div>
                <div className={listBuilderCount > 0?'navbartableelement':'navbartableelement disabled'}  onClick={()=>store.dispatch(setTriggerDeleteSelection(true))}>
                    <figure className="icon deleterowicon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[7]}</div>
                </div>
                <div className={pinColumns?'navbartableelement selected':'navbartableelement'}  onClick={()=>store.dispatch(setPinColumns(!pinColumns))}>
                    <figure className="icon gridlockicon"></figure>

                    <div className="navbartableelementtext">{ToolsOptions[8]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>Export</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        {(popUpContext == FileOptions[0] ||popUpContext =='openunsaved') &&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">Are you sure?</div>
                <div className="confirmationmodaldiv">This will delele all records in the current list builder.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => handleOkNewClick(popUpContext)}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (handleCloseFilePopup())}>Cancel</button>
                </div>
            </div>
        }
        {confirmOverWritePopUp &&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">Are you sure?</div>
                <div className="confirmationmodaldiv">This will overwrite {saveAsName}.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => (handleOkOverWriteClick(),setConfirmOverWritePopUp(false))}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (handleCloseFilePopup())}>Cancel</button>
                </div>
            </div>
        }
        {confirmDeletePopUp &&
            <div className="deletelistbuilderpopup layoutcolumn">
                <div className="confirmationmodaldiv">Are you sure?</div>
                <div className="confirmationmodaldiv">This will permanently delete this list.</div>
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => (handleDelete(),setConfirmDeletePopUp(false))}>OK</button>
                    <button className='confirmationmodalbutton' onClick={() => (setConfirmDeletePopUp(false))}>Cancel</button>
                </div>
            </div>
        }
        {popUpContext == FileOptions[1] &&
            <div className="openlistbuilderPopup layoutcolumn">
                <div className="headerdiv" >
                    <div className="header">Open List</div>
                </div>
                <div className="filelist">
                    <div className="listbuilderpopuptable">
                        <div className="listbuilderpopuptableheader">
                            <div className=" listbuilderfilename">File name</div>
                            <div className=" listbuildersharedheader">Shared</div>
                        </div>
                        <div className="listbuilderpopuptablebody">
                            {savedLists.map((file,index) => (
                                    <div className={file == selectedList ? "fileitem selected" : 'fileitem'}  tabIndex={index}  key={file.listID} onFocus={() => setSelectedList(file)}> 
                                        <div className="listbuilderfilename"> {file.label}</div>
                                        <div className="listbuildershared">{file.sharable==1 &&<DoneIcon sx={{color:'lightblue.main', height:'18px', width:'18px'}} />} </div>
                                    </div>
                            ))}
                        </div>
                    </div>
                </div>
                <input className="filelistbuilderfieldopen" value={selectedList? selectedList.label: ''} disabled />
                <div className="filelistbuilderbuttondiv">
                    <Button className='openlistbuilderbutton' sx={{ marginLeft: '5px' }} disabled ={selectedList ==undefined} variant="contained" onClick={() => handleOpenClick(false)}>Open</Button>
                    <Button className='openlistbuilderbutton' variant="outlined" onClick={() => (setPopUpContext(null), setSelectedList(undefined))}>Cancel</Button>
                    <Button className='openlistbuilderbutton' sx={{ marginLeft: '5px', marginRight: 'auto' }} color="error" disabled ={selectedList ==undefined} variant="contained" onClick={() => (setConfirmDeletePopUp(true))}>Delete</Button>
                    
                </div>
            </div>
        }
        {popUpContext == FileOptions[3] &&
            <div className="openlistbuilderPopup layoutcolumn">
                <div className="headerdiv" >
                    <div className="header">Save as</div>
                </div>
                <div className="filelist">
                    <div className="listbuilderpopuptable">
                        <div className="listbuilderpopuptableheader">
                            <div className=" listbuilderfilename">File name</div>
                            <div className=" listbuildersharedheader">Shared</div>
                        </div>
                        <div className="listbuilderpopuptablebody">
                            {savedLists.map((file,index) => (
                                <div className={file.label == saveAsName ? "fileitem selected" : 'fileitem'} tabIndex={index} key={file.listID} onFocus={() => (setSelectedList(file),setSaveAsName(file.label),setSharable(file.sharable))}>
                                    <div className="listbuilderfilename"> {file.label}</div>
                                    <div className="listbuildershared">{file.sharable==1 &&<DoneIcon sx={{color:'lightblue.main'}} />} </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="filelistbuildersaveasdiv">
                <input className="filelistbuilderfield" value={saveAsName} onChange={(e)=>setSaveAsName(e.target.value)} />
                <label className="checkboxlabel" htmlFor='sharable'>Share</label>
                <Checkbox name="sharable" checked={sharable ==1} onClick={()=>setSharable(sharable ==0?1:0)} color="secondary" sx={{ color: '#009FE3', p: 0, }} />
                </div>
                <div className="filelistbuilderbuttondiv">
                    <Button className='openlistbuilderbutton' sx={{ marginLeft: '5px' }} disabled ={saveAsName ==''|| isLoading} variant="contained" onClick={() => handleSaveAsClick()}>Save</Button>
                    <Button className='openlistbuilderbutton' variant="outlined" onClick={() => (handleCloseFilePopup())}>Cancel</Button>
                </div>
            </div>
        }
    <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert severity={snackbarSeverity} onClose={handleCloseSnackbar} sx={{ width: '100%' }}>
        {snackbarSeverity== 'success' &&<span>Changes saved successfully!</span>}
        {snackbarSeverity== 'error' &&<span>Save failed!</span>}
        </MuiAlert>
      </Snackbar>
    </>
    )
}