import { useSelector } from "react-redux";
import { selectPinColumns, setPinColumns } from "../../Redux/SearchSlicer";
import store from "../../Redux/ConfigureStore";
import { selectIsAdminUser, selectIsRiverlakeUser } from "../../Redux/AuthorisationSlicer";
import PeopleIcon from '@mui/icons-material/People';
import { useEffect, useState } from "react";
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { UserAdmin } from "../../Models/UserAdmin";
import { ClickAwayListener } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


export default function ToolsBarComponent() {
    const pinColumns = useSelector(selectPinColumns);
    const adminUser = useSelector(selectIsAdminUser);
    const rlUser = useSelector(selectIsRiverlakeUser);
    const [users, setUsers] = useState<UserAdmin[]>([]);
    const [showUsersPopup, setShowUsersPopup] = useState(false);
    const [deleteID, setDeleteID] = useState();
    const [copied, setCopied] = useState(0);
    useEffect(() => {
        if (adminUser) {
            getUsers()
        }
    }, [])
    const getUsers = async () => {
        try {
            var userlist = await ApiHelper.GetUserList();
            if (userlist) {
                setUsers(userlist)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleDeleteClick = (id) => {
        setDeleteID(id)
    }
    const handleDelete = async () => {
        try {
            await ApiHelper.DeleteUser(deleteID);
            setDeleteID(undefined)
        }
        catch (error) {
            console.log(error)
        }
        finally { getUsers() }
    }
    const resetGrid = () => {

    }
    const undo = () => {

    }
    const handleGenerateKey = async () => {

        try {
            var key = await ApiHelper.NewSecretKey()
            if (key && key != '') {
                await navigator.clipboard.writeText(key);
                setCopied(1);
            }
            else {
                setCopied(2);
            }
        }
        catch (error) {
            console.log(error)
            setCopied(2);
        }
        finally {
            setTimeout(() => setCopied(0), 2000);

        }
    }
    return (
        <>
            <div className='navbarsearchsection'>
                <div className='navbarsearchselection'>
                    {/* <div className='navbarsearchsectionelement' onClick={undo}>
                        <figure className="icon clearicon"></figure>
                        <div>Rollback Changes</div>
                    </div>
                    <div className='navbarsearchsectionelement' onClick={resetGrid}>
                        <figure className="icon searchicon"></figure>
                        <div>Reset Grids</div>
                    </div> */}
                    <div className={pinColumns ? 'navbarsearchsectionelement selected' : 'navbarsearchsectionelement'} onClick={() => store.dispatch(setPinColumns(!pinColumns))}>
                        <figure className="icon gridlockicon"></figure>
                        <div>Grid Lock</div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>View Settings</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            {adminUser &&
                <>
                    <div className='navbarusersection'>
                        <div className='layoutrow'>
                            <div className='navbarsearchselection'>
                                <div className='navbarsearchsectionelement' onClick={() => setShowUsersPopup(true)}>
                                    <PeopleIcon className='usericon' color="primary" sx={{ width: '33px', height: '33px' }} />
                                    <div>User Admin</div>
                                </div>
                                {rlUser &&
                                    <div className='navbarsearchsectionelement' onClick={() => handleGenerateKey()}>
                                        <VpnKeyIcon className='usericon' color="primary" sx={{ width: '33px', height: '33px' }} />
                                        <div>Generate RL Key</div>

                                    </div>
                                }
                            </div>
                        </div>
                        <div className="navbartableselectiondescription">
                            <div>Admin</div>
                        </div>
                    </div>
                    <div className="vhr">
                    </div>
                    {showUsersPopup && 
                <ClickAwayListener onClickAway={(event) =>(setShowUsersPopup(false))}>
                        <div className='userpopover layoutcolumn'>

                            <div className='closeme' onClick={() => setShowUsersPopup(false)}>
                                <CloseIcon />
                            </div>
                            <div className="headerdiv" >
                                <div className="header">User Admin</div>
                            </div>
                            <div className="useradminpopoverbody ">
                                {users.map((element) => (
                                    <div className="userrow layoutrow" key={element.userName}>
                                        <div className="useradminname">
                                            {element.userName}
                                        </div>
                                        <div className="useradmindelete">
                                            <Button variant="contained" className="startexportbutton" color="error" sx={{ p: '6px', width: '40px', minWidth: '40px', marginRight: '4px' }} onClick={(e) => handleDeleteClick(element.id)}><DeleteIcon /></Button>

                                        </div>
                                    </div>
                                ))}
                                {users.length == 0 &&
                                    <>
                                        No Users associated with your account!
                                    </>
                                }
                            </div>

                        </div>
                        </ClickAwayListener>
                    }
                </>
            }
            {deleteID &&
                <div className="deletelistbuilderpopup layoutcolumn">
                    <div className="confirmationmodaldiv">Are you sure?</div>
                    <div className="confirmationmodaldiv">This will permanently delete this User.</div>
                    <div className=" confirmationmodaldiv">
                        <button className='confirmationmodalbutton' onClick={() => (handleDelete())}>OK</button>
                        <button className='confirmationmodalbutton' onClick={() => (setDeleteID(undefined))}>Cancel</button>
                    </div>
                </div>
            }
            {copied !=0 && <div className="deletelistbuilderpopup layoutcolumn">
                {copied == 1 && <div className="confirmationmodaldiv">Copied to clipboard</div>}
                {copied == 2 && <div className="confirmationmodaldiv">Key Request Failed</div>}
                <div className=" confirmationmodaldiv">
                    <button className='confirmationmodalbutton' onClick={() => (setCopied(0))}>OK</button>

                </div>
            </div>
            }

        </>
    )
}